// Checkout.js

import { useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Checkout = (props) => {
    
    // Clone the props.payment_props object to avoid mutating the original object
    let final_payment_props = JSON.stringify(props.payment_props);
    localStorage.setItem("paypal_payment_props", final_payment_props);

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const [paypal_purchase_units, setPaypalPurchaseUnits] = useState(props); 
    const [amount, setAmount] = useState(props.payment_props);    

    const onCreateOrder = (data, actions) => {
        let paypal_payment_props_str = localStorage.getItem("paypal_payment_props");
        let paypal_payment_props = JSON.parse(paypal_payment_props_str);

        if (paypal_payment_props) {
            return actions.order.create({
                purchase_units: [
                    paypal_payment_props
                ],
            });
        } else {
            // Handle the case where paypal_payment_props is not valid
            console.error("Invalid paypal_payment_props:", paypal_payment_props);
            // You might want to return some default value or handle this case appropriately
            return null;
        }
    }

    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then((details) => {
            localStorage.setItem("paypal_payment_props","");
            const name = details.payer.name.given_name;
            console.log(`Transaction completed by ${name}`);
            props.onApproveOrder();
            // Perform any additional actions after order approval
        });
    }

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <>                    
                    <div style={{ width: '300px' }} > 
                        <PayPalButtons 
                            style={{ layout: "vertical", color: "blue", shape: "rect", label: "pay" }}
                            fundingSource="paypal"
                            createOrder={(data, actions) => onCreateOrder(data, actions,paypal_purchase_units)}
                            onApprove={(data, actions) => onApproveOrder(data, actions)}
                        />
                    </div>

                </>
            )}
        </div>
    );
}

export default Checkout;