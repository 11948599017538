import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../style.scss";
import VendorWrapper from "../VendorWrapper";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import SouthEastOutlinedIcon from "@mui/icons-material/SouthEastOutlined";
import useGetPosts from "src/Hooks/useGetPosts";
import { useSelector } from "react-redux";
import Moment from 'moment';
import useSavePosts from "src/Hooks/useSavePosts";
import { Sync } from "@mui/icons-material";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Form } from "react-bootstrap";

function VendorWalletScreen() {

   const { userSignin: userInfo } = useSelector((state) => state);
   const [formStatus, setFormStatus] = useState({msg:"",status:false,id:0});
   const [loading, setLoading] = useState(false);

   const [showModal, setShowModal] = useState(false);
   const [payoutAmount, setPayoutAmount] = useState("");
   const [validationError, setValidationError] = useState("");

   const {data: walletData} = useGetPosts({url: "vendor_wallet",data:{}})
   console.log("Wallet Data:",walletData);
   const {data: transactionData} = useGetPosts({url: "customer_transaction",data:{}})

   const {sendData:sendPayout}=useSavePosts('vendor_payout')
    
    // Function to handle payout to bank account
   const handlePayout = async () => {
		setLoading(true); 
		// Initiate payout process programmatically
		const payoutAmount = walletData?.data?.balance; // Fixed amount for now		
		console.log("Payout ", payoutAmount);
		const result = await sendPayout({amount: payoutAmount});
		result && setFormStatus({ 
			msg: result.message, 
			status: true, 
			id: 4, 
			type: result.status ? "success" : "error" 
		});
		setLoading(false);
	 };	

	const handleOkButtonClick = () => {		
        window.location.reload(); // This will reload the entire page
    };

	const handleCloseModal = () => setShowModal(false);

	const handleOpenModal = () => {
		setPayoutAmount("");
		setValidationError("");
		setShowModal(true);
	};

	const handleAmountChange = (e) => {
		const amount = e.target.value;
		setPayoutAmount(amount);
		if (!amount) {
			setValidationError("Please enter an amount.");
		} else {
			setValidationError("");
		}
	};

	return (
		<div>
			<VendorWrapper>
				<Col xs={12} md={12}>				
				<SweetAlert
					title={formStatus.msg}
					show={formStatus.status}
					onConfirm={handleOkButtonClick}	
					type={formStatus.type} // Add type property to determine error or success
				>
				</SweetAlert>
					<Container>
						<Row>
							<Col xs={12} md={12}>
								<div className="customer prctCont">
									<div className="customerContainer">
										<div className="breadcrumb-n-title">
											<div className="dashboard-headline">
												<h3>Wallet</h3>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col md={12} xs={12}>
								<Container className="project-listing-container">
									<Row>
										<Col md={12} xs={12}>
											<div className="project-lisitng">
												<div className="project-listing-title">
													<p>Geegr Wallet Id</p>
													<span className="ms-2 text-muted">
														{walletData?.data?._id}
													</span>
												</div>
												<div className="project-listing-action-buttons">
													<p>Wallet amount</p>
													<p className="ms-2">${walletData?.data?.balance}</p>
												</div>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={12} xs={12}>
											<div className="project-lisitng">
												<div className="project-listing-title">
													<span className="text-muted">
														Maximum transferable
														anount free of charge
														${walletData?.data?.minimum_transaction_balance}&nbsp;(no transaction
														fee)
													</span>
												</div>
												<div className="dashboard-button">													
													<MDBBtn rippleColor="dark" onClick={handleOpenModal} >
                                                        {loading ? "Processing..." : "Transfer to paypal account"}
                                                    </MDBBtn>
												</div>
											</div>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<Container>
						<Row>
							<Col xs={12} md={12}>
								<div className="customer prctCont">
									<div className="customerContainer">
										<div className="breadcrumb-n-title">
											<div className="dashboard-headline">
											<h3>Wallet transaction history</h3>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
							
					<Container>
						<Row>
							<Col md={12} xs={12}>
								<Container className="project-listing-container">
									<Row>
										<Col>
											<div className="addAddresContainer">
												<MDBTable hover>
													<MDBTableHead>
														<tr>
															<th
																scope="col"
																className="fw-bold"
															>
																Transaction
																Details
															</th>
															<th
																scope="col"
																className="fw-bold"
															>
																Date
															</th>
															<th
																scope="col"
																className="fw-bold"
															>
																Amount
															</th>
														</tr>
													</MDBTableHead>
													<MDBTableBody>
														

														{transactionData?.data && 
															transactionData.data.map((item)=> (
																<tr>
																<th scope='row'>{item?.transaction_in && item.transaction_in +" - "} {item.description}</th>
																	<td>{item.created_at ? Moment(item.created_at).format('DD MMM YYYY'):'-'}</td>
																<td><SouthEastOutlinedIcon color={item?.transacton_type && item.transacton_type==="credit"?"success":"error"} />{item.amount? "  $"+item.amount:" - "}</td>
																</tr>
															))
														}
													</MDBTableBody>
												</MDBTable>
											</div>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Col>
			</VendorWrapper>

			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Enter Payout Amount</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Payout Amount</Form.Label>
						<Form.Control
							type="number"
							value={payoutAmount}
							onChange={handleAmountChange}
							isInvalid={!!validationError}
						/>
						<Form.Control.Feedback type="invalid">
							{validationError}
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							if (payoutAmount) {
								handlePayout();
								handleCloseModal();
							} else {
								setValidationError("Please enter an amount.");
							}
						}}
					>
						Pay to PayPal
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default VendorWalletScreen;
