import React from 'react'

function AnalyticCards(props) {
  return (
    <div className='Analyticcards'>
      <div className='titleContainer'>
      <span>{props.title}</span>
      </div>
      <div className='textIconContainer'>
      <span>{props.noOf}</span>
     <img src={props.logo}/>
      </div>
    </div>
  )
}

export default AnalyticCards
