import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useGetPosts = (request) => {

  request.url=request.url?request.url:"";
  request.data=request.data?request.data:{};
  request.onLoad=request.onLoad?request.onLoad:"true";
  console.log(request.url,'get_checkout_info');
  const [data, setData] = useState(null);
  const [isLoading,setLoading]= useState(true);
  const getState = useSelector((state) => state);
  const urls={    
    // Admin side api
    "admin_employee_list": "/admin/employee_list",
    "admin_delete_employee": "/admin/employee/delete",
  }
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
    "Content-Type": "application/json",
    token: getState?.userSignin?.userInfo?.data?.token,
  };
  var requestOptions = {
    method: "POST",
    body: JSON.stringify(request.data),
    headers: headers,
    redirect: "follow",
  };
  const getdata=(request_data)=>{
    console.log("getdata",request.url);
    requestOptions.body=JSON.stringify(request_data)
    return fetch(process.env.REACT_APP_URL + urls[request.url],requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setData(data)
          setLoading(false)
          return data
        });
  }
  useEffect(() => {
    console.log(request.onLoad,'aiuyy')
    if(request.onLoad=="false"){
      console.log("onloadfasle",'tt');
        setData({})
    } else{
      console.log("onloadtrue",request.url);

        fetch(process.env.REACT_APP_URL + urls[request.url],requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setData(data)
          setLoading(false)
        });
         

    }


  }, [request.onLoad]);

  return {data, isLoading, getdata};
};

export default useGetPosts;