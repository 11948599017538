import React from "react";
import Profile from "../../../../assets/images/profile1.jpg";
import Group from "../../../../assets/images/Group.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
function Description() {
  return (
    <div className="descriptionContainer">
      <div className="desciptionTopContainer">
        <div className="leftContainer">
          <img src={Profile} />
          <div>
            <div>
              <h1>William L. Artman</h1>
            </div>
            <div>
              <MailOutlineIcon />{" "}
              <span>
                <a>DonLArtman@jourrapide.com</a>
              </span>
            </div>
            <div>
              <LocalPhoneIcon /> <span>12000000000</span>
            </div>
            <div>
              <LocationOnIcon />{" "}
              <span>3311 Bicetown RoadNew York, NY 10013</span>
            </div>
          </div>
        </div>
        <div className="riteContainer">
          <div>
            <button>Reset Password</button>
            <button id="deactivate">Deactivate Account</button>
          </div>
          <div></div>
        </div>
      </div>
      <div className="desciptionBottomContainer">
        <span>Description</span>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum. essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum. essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum. essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum. essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset
        </p>
      </div>
    </div>
  );
}

export default Description;
