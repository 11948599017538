import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Moment from 'moment';

const GeegrCreditCustomer = () => {
    const {NewChats:{proposal}} = useSelector((state) => state);
    const [credit, setCredit] = useState([]);

    useEffect(() => {
        setCredit(proposal?.transaction);
    }, [proposal]);

    return (
        // <div class="card mb-3 shadow bg-body rounded-3 vendor-credit">
            // <div class="card-body p-0">
                // <h5 class="card-title border-bottom pt-3 pb-3 ps-3 fs-6 fw-bold">Recent Transactions</h5>
                <div className="vendor-credit">
                    {credit.length>0 && credit?.map((item) => {
                        return <div className="row" key={item._id}>
                        <div className="col-3">
                            <img src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg" className="rounded-3 img-fluid"/>
                        </div>
                        <div className="col-6">
                            <p className="fs-7">
                                <span className="fw-bold">$ {item?.amount}</span> <br/>
                                <span className="text-muted">On {Moment(item.created_at).format('Do MMM YYYY, h:mm a')}</span>
                            </p>
                        </div>
                        <div className="col-3">
                            <p className="text-warning fs-7">{item.transacton_type==='credit'? 'Deposite':'Release to vendor'}</p>
                        </div>
                    </div>
                    })}
                </div>
            // </div>
        // </div>
        
    )
}
export default GeegrCreditCustomer;