
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGetPosts from "src/Hooks/useGetPosts";
const LogsView = () => {
    let { id } = useParams();
    //const {NewChats:{proposal}} = useSelector((state) => state);

    console.log("Log View Propposal ID",id);

   /* const { data:logs, isLoading, getdata } = useGetPosts({
      url: "vendor_log_list",
      data: {"proposal_id":id},
    });
  
  
    useEffect(() => {
      if (id !== '0') {
       // getdata();
      }
    }, [id]);*/

    const formatDate = (createdAt) => {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return new Date(createdAt).toLocaleDateString('en-GB', options);
    };


    return (
      <div className="proposal-container">
          <ul className="timeline">
              
                  <li className="timeline-item mb-3">
                  <h6 className="fw-bold">Work is Started</h6>
                  <p className="text-muted mb-2 fw-bold">24 May 2024</p>
                  <p className="text-muted">
                      Description
                  </p>
                  </li>
              
            
          </ul>
      </div>)
  

    /*
    return (
    <div className="proposal-container">
        <ul className="timeline">
            {logs?.data?.map((log, index) => (
                <li key={index} className="timeline-item mb-3">
                <h6 className="fw-bold">{log?.log_title}</h6>
                <p className="text-muted mb-2 fw-bold">{formatDate(log?.created_at)}</p>
                <p className="text-muted">
                    {log?.log_description}
                </p>
                </li>
            ))}
          
        </ul>
    </div>)
    */
}

export default LogsView;