import React, { useState } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import VisibilityIcon from "@mui/icons-material/Visibility";
function ServiceOffering() {
  const [basicActive, setBasicActive] = useState("tab1");
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <div className="serviceoffering">
      <div className="serviceofferingTop">
        <div>
          <MDBTabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
              >
                Electrical
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Carpenter
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
      </div>
      <div className="serviceofferingBottom">
        <div className="leftSideContainerservice">
          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"}>
              <div>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </p>
                <p>
                  only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. essentially unchanged. It was
                  popularised in the 1960s with the release
                </p>
              </div>
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"}></MDBTabsPane>
          </MDBTabsContent>
        </div>
        <div className="riteSideContainerservice">
          <div className="leftUploadBtnContainer">
            <p>Relevant Document</p>
            <div className="uploadBtn">
              <span>
                identity-card-document.pdf
                <VisibilityIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceOffering;
