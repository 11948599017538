import { useEffect, useState } from "react"
import useSavePosts from "src/Hooks/useSavePosts";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from 'moment';
//import PaymentModel from "../../Components/Payment/PaymentModel";
const ChatProposal = ({message,onPropsalRequest}) => {
    const {NewChats: {proposal}}=useSelector((state) => state);
    const [confirmStatus,setConfirmStatus] = useState({msg:"",status:false});
    const {data,sendData}=useSavePosts('vendor_accept_proposal')

    const [paymentRequestData,setPaymentRequestData]=useState({})
    const [paymentModalState,setPaymentModalState]=useState(false)
    const [totalBudget, setTotalBudget] = useState(0);

    let { id } = useParams();
    
    const acknowledge =async () =>{
        await sendData({proposal_id: id})
        setConfirmStatus({msg:"Payment Done, Project Started successfully",status:true})
    }
    // const PaymentDone = async (response) =>{
    //     if(response.status){
    //         await sendData({proposal_id: id})
    //         setConfirmStatus({msg:"Payment Done, Project Started successfully",status:true})
    //     }
    // }

    console.log("Message :",message);

    useEffect(() => {
        let budget = 0;
        message?.data?.line_items?.map((mvalue)=>{
            budget += mvalue.budget;
        });
        setTotalBudget(budget);
    }, [message]);
    
    return (<div className="project-proposal-details chat">
        <SweetAlert
            success
            title={confirmStatus.msg}
            show={confirmStatus.status}
            onConfirm={()=>{ setConfirmStatus({msg:"",status:false});  }}
        >
            Successfully
        </SweetAlert>
        {/* <PaymentModel 
            payment_show={paymentModalState} 
            request_data={paymentRequestData} 
            onOutSideClick={() => setPaymentModalState(false)}
            onPaymentDone={(response) => PaymentDone(response)}
        /> */}
        <h3 className="fs-6 fw-bold">Proposal</h3>
 
        <div className="desc fs-6">
            {console.log(message.data.line_items,'msg')}
            {message.data.description} 
        </div>
        <div className="desc">
            <h3 className="item-service-title">Service Items</h3>
            <ul className='line-item-container'>
                
            {message?.data?.line_items?.map((mvalue)=>{
                return (<li>
                    <div className='line-items'>
                        <div className='details'>
                            <div className='title-wrapper'>
                                <h4>{mvalue.title} - <span>${mvalue.budget}</span></h4>
                                <div className='status'>
                                    {mvalue.status && mvalue.status=='approved' && (
                                        <>
                                        <span className='alert alert-success'>Approved</span>
                                        </>
                                    )}
                                    {mvalue.status && mvalue.status=='rejected' && (
                                        <>
                                        <span className='alert alert-danger'>Rejected</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <p>{mvalue.description}</p>
                        </div>
                    </div>
                    

                    
                </li>)     
            })}
            </ul>
             
        </div>
        <div className="amount">
            {/* <span className="amounttitle">Total Amount <b>${message.data.budget}</b></span> */}
            <span className="amounttitle">Total Amount <b>${totalBudget}</b></span>
        </div>
        <div className="actionbuttons">
        {message.is_actionable && (
            <>
            {/* <a onClick={(e) => onAcceptProposal()}  className="btn btn-success">Accept All</a> */}
            {/* <a  onClick={(e) => onPropsalRequest({inline_items:proposal.line_items})} className="btn btn-primary">Request To Update Proposal</a> */}
            <a onClick={(e) => acknowledge(e)}  className="btn btn-success">Acknowledge</a>
            <a onClick={(e) =>null}  className="btn btn-danger">Decline</a>
            </>
        )}
        </div>
        <div className="proposalid">
            <div className="proposaldate">
                <span>{Moment(message.created_at).format('DD MMMM, YYYY')}</span>
            </div>    
            <h5>Proposal ID: {message.proposal_id}</h5>
        </div>
        </div>)
}

export default ChatProposal;
