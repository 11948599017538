import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const useSendFormData = (request_url = "") => {
  const [data, setData] = useState(null);
  const getState = useSelector((state) => state);
  const urls = {
    'update_project': "/customer/project/update",
    'add_project': "/customer/project/add",
    "vendor_proposal_submit": "/vendor/proposal/add",
    'customer_send_file':'/customer/chat/send_files',
    'vendor_send_file':'/vendor/chat/send_files',
    "profile_update": "/customer/profile/update",
    "change_password":"/customer/profile/password/update", 

  };

  async function sendForm(body) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_URL + urls[request_url],
        body,
        {
          headers: {
            token: getState?.userSignin?.userInfo?.data?.token,
            "Content-Type": "multipart-data/form-data",
          },
        }
      );
      setData(response.data); 
      return response.data; 
    } catch (error) {
      console.error(error);
      throw error; 
    }
  }

  return { sendForm, data }; 
};

export default useSendFormData;
