import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BsSearch } from "react-icons/bs";
import ServiceCard from "../components/ServiceCard";
import { useParams } from "react-router-dom";
import Wrapper from "../../../Utlilities/Wrapper";
import useFetchPost from "../../../Hooks/useFetchPost";
import Rating from "../../../Components/rating/Rating";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useGetPosts from "../../../Hooks/useGetPosts";


function ServiceList() {
  //states
  const [name, setName] = useState("Tom Cruise");
  const [rate, setRate] = useState(60);
  const [successRate, setSuccessrate] = useState(95);
  const [params, setParams] = useState("");
  const [changeLocation, setChangeLocation] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSortOption, setSelectedSortOption] = useState("A to Z");
  const [range, setRange] = useState();
  const wrapperHeight = "14vh";
  const { id } = useParams();

  const [orderBy, setOrderTitle] = useState("A to Z");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotaltPage] = useState(0);

  const [loading, setLoading] = useState(true);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const handleChange = (e) => {
    setParams(e.target.value);
  };
  const handleChangeLocation = (e) => {
    setChangeLocation(e.target.value);
  };

  const handleCategorySelect = (categoryTitle) => {
    const isSelected = selectedCategories.includes(categoryTitle);
  
    if (isSelected) {
      // If already selected, remove from the list
      setSelectedCategories(selectedCategories.filter((title) => title !== categoryTitle));
    } else {
      // If not selected, add to the list
      setSelectedCategories([...selectedCategories, categoryTitle]);
    }
  };


  const handleSortByDropdownItemClick = (key) => {
    switch (key) {     
      case "a_to_z":
        setOrderTitle("A to Z");
        setSelectedSortOption('A to Z');
        break;
      case "z_to_a":
        setOrderTitle("Z to A");
        setSelectedSortOption('Z to A');
        break;
      default:
        // Handle default case if needed
        break;
    }
  };


  var search = {
    cat_id: id,
    s: params,
    location: changeLocation,
    service_charge: range,
    page:currentPage,
    category:selectedCategories,
    orderBy:orderBy,
  };

  var body = JSON.stringify(search);


  const { getdata, data: services,totPages: totalDataPages }=useGetPosts({url: 'front_services',data:search})

  useEffect(() => {
    getdata();
  }, [params, changeLocation, range,currentPage,selectedCategories,orderBy]);  

  // Log the value of totalDataPages whenever it changes
  useEffect(() => {
    // Set loading to false once the data is available
    setLoading(false);
  }, [totalDataPages]);

  // Set the value of totPages to the state variable setTotaltPage
  useEffect(() => {
    setTotaltPage(totalDataPages);
  }, [totalDataPages]);

  return (
    <>
      <Wrapper wrapperHeight={wrapperHeight} />
      <Container className="">
        <Row>
          <Col sm={3} md={3}>
            <Container>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 select-category-div">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Service Category
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "none",
                          background: "#FFFFFF",
                          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.08)",
                          borderRadius: "5px",
                        }}
                      >                        
                        <span>{selectedCategories.length > 0 ? selectedCategories.join(', ') : 'Service Category'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      {services?.category.map((categoryList) => (
                        <Dropdown.Item
                          key={categoryList.title}
                          onClick={() => handleCategorySelect(categoryList.title)}
                        >
                          {categoryList.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>

                    </Dropdown>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 select-category-div">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Filters
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Location
                    </p>
                    <div
                      className="search"
                      style={{
                        display: "flex",
                        justifyContent: "spaceBetween",
                        flexDirection: "revert",
                        alignItems: "center",
                        padding: "0px 10px 0px 10px",
                        height: "48px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control z-depth-1"
                        placeholder="Location"
                        onChange={(e) => handleChangeLocation(e)}
                        style={{
                          textIndent: "0px",
                          outline: "none",
                          border: "none",
                          minHeight: "100%",
                        }}
                      />
                      <LocationOnIcon />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row></Row>
              { /* <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",

                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Reviews
                    </p>
                    <div className="rating-div">
                      <div
                        className="rating-div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "95%",
                          }}
                        >
                          <input type="checkbox" />
                          <div
                            style={{
                              display: "flex",
                              flex: "auto",
                              marginLeft: "5px",
                            }}
                          >
                            <Rating rating={5} maxRating={5} />
                          </div>
                          <span
                            style={{
                              fontFamily: "Nunito Sans",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: "#3D3939",
                            }}
                          >
                            5
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input type="checkbox" />
                          <div
                            style={{
                              display: "flex",
                              flex: "auto",
                              width: "146px",
                              marginLeft: "5px",
                            }}
                          >
                            <Rating rating={4} maxRating={4} />
                          </div>
                          <span
                            style={{
                              fontFamily: "Nunito Sans",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: "#3D3939",
                            }}
                          >
                            4+
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input type="checkbox" />
                          <div
                            style={{
                              display: "flex",
                              flex: "auto",
                              width: "146px",
                              marginLeft: "5px",
                            }}
                          >
                            <Rating rating={3} maxRating={3} />
                          </div>
                          <span
                            style={{
                              fontFamily: "Nunito Sans",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: "#3D3939",
                            }}
                          >
                            3+
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input type="checkbox" />
                          <div
                            style={{
                              display: "flex",
                              flex: "auto",
                              width: "146px",
                              marginLeft: "5px",
                            }}
                          >
                            <Rating rating={2} maxRating={2} />
                          </div>
                          <span
                            style={{
                              fontFamily: "Nunito Sans",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: "#3D3939",
                            }}
                          >
                            2+
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <input type="checkbox" />
                          <div
                            style={{
                              display: "flex",
                              flex: "auto",
                              width: "146px",
                              marginLeft: "5px",
                            }}
                          >
                            <Rating rating={1} maxRating={1} />
                          </div>

                          <span
                            style={{
                              fontFamily: "Nunito Sans",
                              fontWeight: "600",
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: "#3D3939",
                            }}
                          >
                            1+
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                          </Row> */}
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 select-category-div">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Sort By
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "none",
                          background: "#FFFFFF",
                          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.08)",
                          borderRadius: "5px",
                        }}
                      >
                        
                        <span>{selectedSortOption ? `Sort By: ${selectedSortOption}` : 'Sort By'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>                
                      <Dropdown.Item key="a_to_z"  onClick={() => handleSortByDropdownItemClick("a_to_z")}>
                            Sevice Name : A to Z
                          </Dropdown.Item>
                          <Dropdown.Item key="z_to_a"  onClick={() => handleSortByDropdownItemClick("z_to_a")}>
                            Sevice Name : Z to A
                          </Dropdown.Item>                               
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>                          
              {/*<Row>
                <Col md={12} sm={12} xs={12}>
                  <p
                    style={{
                      fontFamily: "Nunito Sans",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "27px",
                      color: "#3D3939",
                    }}
                  >
                    Keywords
                  </p>
                  <div className="my-3 skills-buttons-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "248px",
                        height: "48px",
                        left: "88px",
                        top: "782px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.08)",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="e.g task title"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                      />
                      <AddBoxIcon
                        style={{
                          color: "#6A2FF9",
                          fontSize: "2.3rem",
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
               <Row>
                <Col md={12} sm={12} xs={12}>
                  <p
                    style={{
                      fontFamily: "Nunito Sans",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "27px",
                      color: "#3D3939",
                    }}
                  >
                    Sub Category
                  </p>
                  {services?.data
                      .filter((service, index, self) =>
                        index === self.findIndex((s) => s.sub_category.title === service.sub_category.title)
                      )
                      .map((service) => (
                        <div className="my-3 skills-buttons-div" key={service.sub_category.title}>
                          <div
                            className="sub-category"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              color: "#707070",
                              fontSize: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ backgroundColor: "#ffff", padding: "5px" }}>
                              {service.sub_category.title}
                            </span>
                          </div>
                        </div>
                      ))}

                </Col>
              </Row>
                          */}
            </Container>
          </Col>
          <Col md={9} sm={9} xs={9}>
            <Container>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="search-panel-service">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Search List
                    </p>
                    <span>{<BsSearch />}</span>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Search by relevance"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => handleChange(e)}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              

              <Row>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {services?.data?.map((service) => (
                      <ServiceCard
                      key={service._id}
                      name={name}
                      title={service.title}
                      rating={service.rating}
                      vertified={service.vertified}
                      location={service.location}
                      rate={rate}
                      successRate={successRate}
                      id={service._id}
                      subtitle={service.category.title}
                      serviceCharge={service.service_charge}
                      displayImage={service.display_image}
                    />
                    ))}
                    <div>
                      <p>Page {currentPage} of {services?.totPages}</p>
                      <button className="btn btn-primary custom-btn-geegr"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous Page
                      </button>&nbsp;&nbsp;&nbsp;
                      <button className="btn btn-primary custom-btn-geegr"
                        disabled={currentPage === services?.totPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next Page
                      </button>
                    </div>
                  </>
                )}
              </Row>   

            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ServiceList;
