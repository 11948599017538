import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import ActiveUsers from 'src/Screens/Dashboard/Customer/Chat/Components/ActiveUsers';
import ChatScreen from "src/Screens/Dashboard/Customer/Chat/Components/ChatScreen";
import Wrapper from "src/Utlilities/Wrapper";
import { useParams, useNavigate } from "react-router-dom";
import useGetPosts from "src/Hooks/useGetPosts";
import useAddSocket from "src/Hooks/UseAddSocket";
import "src/Screens/Dashboard/Customer/Chat/style.scss";
const wrapperHeight = "11vh";
//let socketon=false;
function CustomerChat() {
  const navigate = useNavigate();
  const {data: chatList}=useGetPosts({url: "message_list",data:{}})
  let { id } = useParams();
  //const [proposal,setProposal]=useState(id)
  chatList?.data.vendors.sort((a,b) => {return new Date(b.created_at).getTime() - new Date(a.created_at).getTime() });
  if(id === 0 || id === "0" && chatList?.data.vendors.length > 0) {
    navigate("/customer_chat/"+ chatList?.data.vendors[0]._id);
  }
  useAddSocket(id);
  
  
  return (
    <>
    
    <Wrapper wrapperHeight={wrapperHeight} />
    <Container fluid className="pt-3">
      <Row className="customerscreen">
        <Col xs={3} md={3}>
          <ActiveUsers users={chatList} />
        </Col>
        <Col xs={9} md={9}>
          <ChatScreen />
        </Col>
      </Row>
    </Container>
    
  </>
  );
}

export default CustomerChat;
