import React, { useEffect } from 'react';

export default function HelpWidget() {

	useEffect(() => {
    // Create a script element
    
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://ind-widget.freshworks.com/widgets/1060000000556.js';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Initialize the Freshdesk widget settings here
      window.fwSettings = {
        widget_id: 1060000000556, // Replace with your actual widget ID
        // Add other settings as needed
      };

    };

    // Append the script to the document body
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };

  }, []); // Empty dependency array ensures this effect runs once
	  return (
    <div>
      {/* Your component content here */}
    </div>
  );
}