import React, { useState,useRef,useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../Components/Sidebar/Sidebar";
//import AddMoreUser from "../Dashboard/AdUser";
import TabsOptions from "./Tabs/TabsOptions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./../Admin.scss"



import { Col, Container, Row } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
//import DashBoard from Admin;

import SweetAlert from "react-bootstrap-sweetalert";
import ServiceFrom from "./Form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useGetPosts from "src/Hooks/useGetPosts";
import useSaveEmployees from "src/Hooks/useSaveEmployees";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";


function AddEmployee(){
  let defaultvalues={
    ein: "",
    role:"",
    name:"",
    email:"",
    username:"",
    password:"",
    deleted : false,
    images:[],
    is_immediate:false,
    is_shareable: false,
    max_radius: 200,
    lat: 10,
    long: 15
}

let validations={
    ein: ["required"],
    role: ["required"],
    name: ["required"],
    email: ["required"],
    username: ["required"],
    password: ["required"],
}

const navigate = useNavigate();
const getState = useSelector((state) => state);
const [formStatus, setFormStatus] = useState({msg:"",status:false,id:0});
const [formdata, setFormData] = useState(defaultvalues);


// save project custom hook
const {sendData:saveEmployee}= useSaveEmployees("admin_employee_add");

const  submitService=async (data) => {
    const result=await saveEmployee(data)
    result?.status==true && setFormStatus({msg:'Added',status:true,id:result.data._id})
    setFormData(data);
}

  return (
    <>
      
        <div className="dashBoardBodyContainer">
          { <Sidebar /> }
          
            <div className="tableContainer sm-5">

              
                <div className="container">
                
                <Link to="/admin/employee" style={{ textDecoration: "none", color: "grey"  }}>            
                <p><ArrowBackIcon />Back to User Management</p>            
                </Link>
                
                
                <SweetAlert
                        success
                        title={formStatus.msg}
                        show={formStatus.status}
                        onConfirm={()=>{ setFormStatus({msg:"",status:false}); navigate("/admin/employee") }}
                        >
                        Successfully
                </SweetAlert>
                
                 <Container>
            
                <Row>
                    <Col md={12} xs={12}>
                    <Container className="project-listing-container">
                        <Row>
                            <Col md={12} xs={12}>
                                <div className="project-lisitng">
                                {/* <BsFillBookmarkFill /> */}
                                <div className="project-listing-title">
                                    <BusinessCenterIcon />
                                    Add New Service
                                </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="project-dashboard-card p-0">
                        <ServiceFrom defaultvalues={formdata}  validations={validations} submitService={submitService}  />
                        </div>
                    </Container>
                    </Col>
                </Row>
            </Container>
                </div>
              
            </div>
          
        </div>
      
      
    </>
  );
}

export default AddEmployee;