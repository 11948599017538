import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useState } from "react";
import axios from 'axios'; // Import Axios


const PayPalLoginButton = ({ onLogin }) => {

  const [orderID, setOrderID] = useState('');
  const [error, setError] = useState(null);

  const handleOrderIDChange = (e) => {
    setOrderID(e.target.value);
  };

  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const secret = process.env.REACT_APP_PAYPAL_SECRET_KEY;

  

  const credentials = `${clientId}:${secret}`;
  const base64Credentials = btoa(credentials);

  console.log("clientId: ", clientId);
  console.log("secret: ", secret);

  const handleLogin = async (data, actions) => {
    console.log(":::::userInfo   " , data);
    console.log(":::::orderID   " , data.orderID);
    
    // Get Access Token    
    try {
      // Make a request to PayPal's token endpoint to obtain access token
      const response = await axios.post(`${process.env.REACT_APP_PAYPAL_API_URL}v1/oauth2/token`, null, {
        params: {
          grant_type: 'client_credentials',
        },
        headers: {
           'Content-Type': 'application/x-www-form-urlencoded',
           Authorization: `Basic ${base64Credentials}`, // Replace with your base64-encoded client ID and secret
        },
      });

      const accessToken = response.data.access_token;
      console.log('Access Token:', accessToken);

      // Get Buyer Info
      try {
          const orderResponse = await axios.get(`${process.env.REACT_APP_PAYPAL_API_URL}v2/checkout/orders/${data.orderID}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`, // Replace with your access token
            },
          });

          // Extract buyer email from the response
          const payerEmail = orderResponse.data.payer.email_address;
          console.log("Buyer Email:", payerEmail);          

          // Pass access token back to the parent component
          onLogin(payerEmail);

          // Ajax call to save buyer email and set into Redux

        } catch (error) {
          setError(error.message);
        }

      
    } catch (error) {
      console.error('Error getting access token:', error.response.data);
      setError(error.response.data.error_description || 'Failed to get access token');
    }    

    
  };

  return (
    
        <div style={{ width: '300px' }} > 
            <PayPalScriptProvider options={{ 'client-id': clientId }}>
                
                <PayPalButtons
                  
                  style={{ layout: "horizontal", color: "white", shape: "rect", tagline: "false" ,label: "pay" }}                  
                  onApprove={handleLogin}                  
                />


            </PayPalScriptProvider>

            </div>

  );
};

export default PayPalLoginButton;
