import React from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  MDBBreadcrumb,
MDBBreadcrumbItem,
MDBContainer,
MDBNavbar,
MDBBadge,
} from "mdb-react-ui-kit";
import "./Text.scss";
import { useSelector } from "react-redux";
import Rating from "src/Components/rating/Rating";

function Title() {
  const { userSignin: userInfo } = useSelector((state) => state);
  
  return (
    <>
      {/* <div className="greetUser">
        <h4 className="fw-bold" style={{ textTransform: "capitalize" }}>
          Howdy,{" "}
          {userInfo.isLoggedIn ? (
            <>{userInfo.userInfo.data.user.first_name}</>
          ) : (
            <>Hugo</>
          )}
        </h4>
        <p className="fw-bold">We are gald to see you again!</p>
      </div> */}
      <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="customer prctCont">
                <div className="customerContainer">
                  <div className="breadcrumb-n-title">
                    <div className="dashboard-headline">
                    <h4 className="fw-bold" style={{ textTransform: "capitalize" }}>
                      Howdy,{" "}
                      {userInfo.isLoggedIn ? (
                        <>{userInfo.userInfo.data.user.first_name}</>
                      ) : (
                        <>Hugo</>
                      )}
                    </h4>
                      <div>
                        <div className="d-flex flex-row bd-highlight mb-3 rating">
                          <div class="p-2 bd-highlight"><MDBBadge color="warning">3.5</MDBBadge></div>
                          <div class="p-2 bd-highlight"><Rating rating={3.5} maxRating={5} /></div>
                          <div class="p-2 bd-highlight">
                            3.5 (<Link to="#">1502 Reviews</Link>)
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="breadcrum-ui-kit">
                      <MDBNavbar expand="lg" light bgColor="dark">
                        <MDBContainer fluid>
                          <MDBBreadcrumb>
                            <MDBBreadcrumbItem className="breadcrum-item">
                              <Link to="/"><p>Home</p></Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem className="breadcrum-item">
                              <Link to="/vendor/dashboard"><p>Dashboard</p></Link>
                            </MDBBreadcrumbItem>
                          </MDBBreadcrumb>
                        </MDBContainer>
                      </MDBNavbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </>
  );
}

export default Title;
