//import "src/Screens/Dashboard/Customer/Components/Sidebar/Sidebar.scss";
import "./Sidebar.scss"
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";

const Sidebar = () => {
  //   const { dispatch } = useContext(DarkModeContext);
  const location = useLocation();
  const [subSettings, setSubSettings] = useState(false)

  return (
    <div className="sidebar">
      <div className="center">
        <ul>
          <Link to="/vendor/dashboard" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/dashboard" ? "active" : ""
              }
            >
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to="/vendor/chat/0" style={{ textDecoration: "none" }} >
            <li
              className={location.pathname == "/customer_chat" ? "active" : ""}
            >
              <MessageOutlinedIcon className="icon" />
              <span>Message</span>
            </li>
          </Link>

          <Link to="/vendor/services" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/services" ? "active" : ""
              }
            >
              <WorkOutlineOutlinedIcon className="icon" />
              <span>Services</span>
            </li>
          </Link>

          <Link to="/vendor/service_requests" style={{ textDecoration: "none"}}>
            <li
              className={
                location.pathname == "/vendor/service_requests" ? "active" : ""
              }
            >
              <WorkOutlineOutlinedIcon className="icon" />
              <span>Service Requests</span>
            </li>
          </Link>

          <Link to="/vendor/projectproposals" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/projectproposals" ? "active" : ""
              }
            >
              <StoreIcon className="icon" />
              <span>Projects</span>
            </li>
          </Link>
          <Link to="/vendor/profile" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/profile" ? "active" : ""
              }
            >
              <PermIdentityOutlinedIcon className="icon" />
              <span>My Profile</span>
            </li>
          </Link>

          <Link to="/vendor/wallet" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/wallet" ? "active" : ""
              }
            >
              <AccountBalanceWalletOutlinedIcon className="icon" />
              <span>Geegr Wallet</span>
            </li>
          </Link>

          <Link to="/vendor/transaction_history" style={{ textDecoration: "none" }}>
            <li
              className={
                location.pathname == "/vendor/transaction_history" ? "active" : ""
              }
            >
              <SwapHorizOutlinedIcon className="icon" />
              <span>Transaction History</span>
            </li>
          </Link>

          <Link to="#" style={{ textDecoration: "none" }}>
          <li className={location.pathname == "/support" ? "active" : ""}>
            <HeadsetMicOutlinedIcon className="icon" />
            <span>Support</span>
          </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
