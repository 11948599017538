import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatBody from 'src/Screens/Dashboard/Customer/Chat/Components/ChatBody';
import ProposalView from 'src/Screens/Dashboard/Customer/Chat/Components/ProposalView';
import { Accordion } from "react-bootstrap";
//import ProposalToDo from "./ProposalToDo";
import ProposalToDo from "./ProposalToDO";
import AboutVendor from "./AboutVendor";
import ActiveUsers from 'src/Screens/Dashboard/Customer/Chat/Components/ActiveUsers';
import RaiseDispute from "./RaiseDispute";
import GeegrCreditCustomer from "./GeegrCreditCustomer";
import { BsFillBookmarkFill } from "react-icons/bs";
// import useFetchPost from "../../../Hooks/useFetchPost";
import { useSelector } from "react-redux";

const ChatScreen = () => {
  const {NewChats:{proposal}} = useSelector((state) => state);
  
  const initialAccordionItems = [
    {eventKey: 0, heading: "About Vendor", component: <AboutVendor />, display: true},
    {eventKey: 1, heading: "Proposal", component: <ProposalView />, display: true},
    {eventKey: 2, heading: "To Do", component: <ProposalToDo />, display: false},
    {eventKey: 3, heading: "Raise Dispute", component: <RaiseDispute />, display: false},
    {eventKey: 4, heading: "Recent Transactions", component: <GeegrCreditCustomer />, display: false},
  ]

  const [accordionItems, setAccordionItems] = useState(initialAccordionItems);

  useEffect(() => {
    //console.log('proposal.todo.length', proposal?.todo??false);
    //console.log('proposal.todo.length', proposal);
    initialAccordionItems[2].display = (proposal?.todo.length>0)?true:false;
    initialAccordionItems[3].display = (proposal?.todo.length>0)?true:false;
    initialAccordionItems[4].display = (proposal?.todo.length>0)?true:false;
    setAccordionItems(initialAccordionItems);
  }, [proposal]);

  return (
    <>
    <Col xs={12} md={12}>
      {/* <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="customer">
              <div className="customerContainer">
                <div className="breadcrumb-n-title">
                  <div className="dashboard-headline">
                    <h3>Manage Projects</h3>
                  </div>
                  <div className="breadcrum-ui-kit">
                    <MDBNavbar expand="lg" light bgColor="dark">
                      <MDBContainer fluid>
                        <MDBBreadcrumb>
                          <MDBBreadcrumbItem>
                            <a href="#">Home</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem>
                            <a href="#">Library</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem active>
                            <a href="#">Data</a>
                          </MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                      </MDBContainer>
                    </MDBNavbar>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container>
        <Row>
          <Col md={12} xs={12}>
            <Container className="customer-chat-container">
              <Row>
                <Col md={8} xs={8}>
                    <div className="chatmainbody">
                      <ChatBody />
                    </div>
                </Col>
                <Col md={4} xs={4}>
                 <div className="chatrightbar">

                    <Accordion defaultActiveKey="0">

                      {accordionItems.map((item) => {
                        if(item.display) {
                        return <Accordion.Item eventKey={item.eventKey}>
                          <Accordion.Header><h3>{item.heading}</h3></Accordion.Header>
                          <Accordion.Body>
                            {item.component}
                          </Accordion.Body>
                        </Accordion.Item>
                        }
                      })}
                      
                  </Accordion>

                  </div>
                </Col>
              </Row>     
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  </>
  );
};

export default ChatScreen;
