import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import ActiveUsers from 'src/Screens/Dashboard/Vendor/Chat/ActiveUsers/Index';
import ChatScreen from "src/Screens/Dashboard/Vendor/Chat/ChatScreen/Index";
import Wrapper from "src/Utlilities/Wrapper";
import { useParams, useNavigate } from "react-router-dom";
import useGetPosts from "src/Hooks/useGetPosts";
import useAddVendorSocket from "src/Hooks/UseAddVendorSocket";
import "src/Screens/Dashboard/Vendor/Chat/style.scss";

const wrapperHeight = "11vh";
//let socketon=false;
function Index() {
  const navigate = useNavigate();
  const {data: chatList}=useGetPosts({url: "vendor_proposal_list",data:{}})
  let { id } = useParams();
  chatList?.data.sort((a,b) => {return new Date(b.created_at).getTime() - new Date(a.created_at).getTime() });
  
  if(id === 0 || id === "0" && chatList?.data.length > 0) {
    navigate("/vendor/chat/"+ chatList?.data[0]._id);
  }
  
  useAddVendorSocket(id);
  
  return (
    <>
    
    <Wrapper wrapperHeight={wrapperHeight} />
    <Container fluid className="pt-3">
      <Row className="vendorscreens">
        <Col xs={3} md={3}>
          <ActiveUsers proposals={chatList} />
        </Col>
        <Col xs={9} md={9}>
          <ChatScreen />
        </Col>
      </Row>
    </Container>
    
  </>
  );
}

export default Index;
