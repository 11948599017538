import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useSavePosts from "src/Hooks/useSavePosts";
import SweetAlert from "react-bootstrap-sweetalert";
import { 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
 } from "mdb-react-ui-kit";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Alert from 'react-bootstrap/Alert';
import { Col, Container, Row } from "react-bootstrap";

const RaiseDispute = () => {
    let { id } = useParams();
    const {NewChats:{proposal}} = useSelector((state) => state);
    const {sendData}=useSavePosts('vendor_raise_dispute')
    const [responseData, setResponseData] = useState({});
    const [confirmStatus,setConfirmStatus] = useState({msg:"",status:false});
    const [showModal, setShowModal] = useState(false);
    const hiddenFileInput = useRef(null);
    const formRef = useRef(null);
    const [alertMessage, setAlertMessage] = useState({variant: false, message:'', status: false});
    const [isSubmit, setIsSubmit] = useState(false);

    let initFormValue = {
        type: '',
        description: ''
    };
    const [formData, setFormData] = useState(initFormValue);

    useEffect(() => {
        setResponseData(proposal);
    }, [proposal]);

    const handleChange = (e) => {
        delete formData?.error;
        setFormData({...formData,[e.target.name]:e.target.value});
      }

    const handleLineItem = (e) => {
        e.preventDefault();
        let error = {}
        //console.log('formData:', formData);
        if(formData.type === '') {
            error.type = 'Type is required!';
        }
        if(formData.description === '') {
            error.description = 'Description is required!';
        }
        setFormData({...formData, error});
        if(Object.keys(error).length === 0) {
            //setIsSubmit(true);
            RaiseDispute(formData);
            setShowModal(false);
        }
    }

    const handleFile = (e) => {
        e.preventDefault();
        hiddenFileInput.current.click();
    }

    const RaiseDispute = async (data) => {
        //setIsSubmit(false);
        //setLineItemModalModal(false);
        let response = await sendData({ 
            proposal_id: responseData._id,
            ...data
            });
        setResponseData(response.data);
        setConfirmStatus({msg:"Dispute Raised",status:true});
    }

    return (
        <>
        <SweetAlert
            success
            title={confirmStatus.msg}
            show={confirmStatus.status}
            onConfirm={()=>{ setConfirmStatus({msg:"",status:false});  }}
        >
            Successfully
        </SweetAlert>
    <div className="proposal-container">
    <ul className="line-item-container">
        {responseData.dispute !== null && (
                <li className="pb-1 mb-1 border-botto">
                <div className='line-items'>
                    <div className="details">
                        <div className="title-wrapper">
                            <h4>{responseData?.dispute?.from}</h4>
                            <div className="status">
                                <span className='alert alert-success'>{responseData?.dispute?.type}</span>
                                
                            </div>
                        </div>
                        <p>{responseData?.dispute?.description}</p>
                    </div>
                </div>
            </li>
            )}
    </ul>

    {/* <button className="btn btn-light">Request New Line Item</button> */}
    {responseData.dispute === null && (
        <MDBBtn color='none' tag='a' rippleColor='dark' className="btn-color" onClick={() => setShowModal(true)}>
            <AddOutlinedIcon /> Raise Dispute
        </MDBBtn>
    )}
    </div>

    <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1' className="custom-modal" onHide={()=>{ 
                            setFormData(initFormValue);
                          //setAlertMessage({variant: false, message:'', status: false});
                          setIsSubmit(false);
                        }} staticBackdrop>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader className="text-center">
              <MDBModalTitle>Raise Dispute</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => setShowModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            {alertMessage.variant && <Alert key={alertMessage.variant} variant={alertMessage.variant}>
              {alertMessage.message}
            </Alert>}
              <form onSubmit={handleLineItem} className="has-validated" noValidate>
                <Row>
                    <Col md={4} className="">
                        <label className="form-label">Types of Dispute</label>                        
                    </Col>
                    <Col md={8} className="">
                        <select className={`form-select ${formData?.error?.type?'is-invalid':''}`} onChange={(e) => handleChange(e)} value={formData.type} name="type" >
                            <option defaultValue={formData.type===''?true:''}>-- Select --</option>
                            <option value="Behaviour">Behaviour</option>
                            <option value="Payment not on time">Payment not on time</option>
                        </select>
                        <div className={`${formData?.error?.type?'invalid-feedback':''}`}>
                            {formData?.error?.type??''}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={12} sm={12} className="">
                        <label className="form-label">Share your comments.</label>
                        <textarea className={`form-control ${formData?.error?.description?'is-invalid':''}`} name="description" onChange={(e) => handleChange(e)} value={formData.description}  placeholder="Enter Comment" rows="6" />
                        <div className={`${formData?.error?.description?'invalid-feedback':''}`}>
                            {formData?.error?.description??''}
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12} sm={12}>
                    <MDBBtn outline className="" onClick={handleFile}>
                      Upload File
                    </MDBBtn>
                    <input type="file" ref={hiddenFileInput} className="d-none"/>
                    <span className="text-muted mt-1 ps-3">Images or docs this might be helpful resolving your dispute.</span>
                    </Col>
                </Row>
                  <input type="submit" ref={formRef} className="d-none" />
                </form>
            </MDBModalBody>

            <MDBModalFooter>
                {/* <MDBBtn outline onClick={() => setShowModal(false)} className="btn-color">Cancel</MDBBtn> */}
                <MDBBtn onClick={(e) => formRef.current.click()} className="btn-color-2">Submit</MDBBtn>
            </MDBModalFooter>
            
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>)
}

export default RaiseDispute;