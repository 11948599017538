import { useState,useEffect } from "react";
import React from "react";
import NotificationLogo from "../../assets/images/complaint_svgrepo.png";
import useGetPosts from "src/Hooks/useGetPosts";
import useSavePosts from "src/Hooks/useSavePosts";


function Notification() {
  const [clearAll, setClearAll] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const { data, isLoading, getdata } = useGetPosts({
    url: "customer_notification",
    data: {},
  });

  //const {sendData:data2}= useSavePosts("customer_notification_read");
  const {sendData:getNotificationdataRead}=useSavePosts('customer_notification_read')



  useEffect(() => {
    // Load notification data on page load
    getdata();
  }, []);

  useEffect(() => {
    if (data) {
      setNotificationData(data); // Set notification data when data is available
    }
  }, [data]);


  const handleClearAllClick = async () => {
    setClearAll(true);
    // Call function to clear data
    try {
        const response = await getNotificationdataRead();
        // Assuming response contains updated notification data
        if (response && response.data) {
            setNotificationData(response.data); // Update notification data with the response
        }
    } catch (error) {
        // Handle error if any
        console.error("Error clearing notification data:", error);
    }
 };

  
  return (
    <>
    <div className="box">
                    <div className="group">
                        <div className="overlap-group">
                            <img className="line" src="img/line-8.svg" />
                            <div className="text-wrapper" onClick={handleClearAllClick}>Clear All</div>
                            <div className="scrollable-container" >
                                   { notificationData?.data && notificationData?.data.map((item)=>(
                                    <div className="overlap">
                                    <img className="ellipse" src={NotificationLogo} />
                                    <div className="content">
                                        <p className="div">
                                            <span className="span">{item.sender_name}</span>
                                            <span className="text-wrapper-2">{item.message}</span>                                            
                                        </p>
                                        <div className="text-wrapper-4">{item.sent_time}</div>
                                    </div>
                                    </div> 

                                   ))
                                  }
                                                                       
                            </div>
                            <div className="text-wrapper-6">Notifications</div>
                        </div>
                    </div>
                </div>
    </>) ; 
}

export default Notification;
