import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../Components/Sidebar/Sidebar";
//import AddMoreUser from "../Dashboard/AdUser";
import TabsOptions from "./Tabs/TabsOptions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./../Admin.scss"
//import DashBoard from Admin;
function Index() {
  const [selectAll, setSelectAll] = useState(false);
  const [addItem, setItem] = useState();
  const [openDetails, setOpenDetails] = useState();
  const [data, setData] = useState([
    {
      id: 1,
      name: "William",
      lastName: "29 May 23",
      EmailID: "Ira M Clark",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View",
      selected: false,
    },
    {
      id: 2,
      name: "Joshua",
      lastName: "29 May 23",
      EmailID: "Kevin C Garrett",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View",
      selected: false,
    },
    {
      id: 3,
      name: "Lee",
      lastName: "29 May 23",
      EmailID: "Jerry T Wortman",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View",
      selected: false,
    },
    {
      id: 4,
      name: "Jerry",
      lastName: "29 May 23",
      EmailID: "Joshua IGriffin",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View",
      selected: false,
    },
    {
      id: 5,
      name: "Kevin",
      lastName: "29 May 23",
      EmailID: "LeeJ T hompson",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View",
      selected: false,
    },
    {
      id: 6,
      name: "Ira",
      lastName: "29 May 23",
      EmailID: "Don Artman",
      PhoneNumber: "Withdraw",
      Rating: "8.0",
      Actions: "View77",
      selected: false,
    },

    // Add more data as needed
  ]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };
  const handleCheckboxChange = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setSelectAll(updatedData.every((item) => item.selected));
  };
  return (
    <>
      <div>
        <div className="dashBoardBodyContainer">
          { <Sidebar /> }
          {openDetails ? (
            <TabsOptions />
              ) : (
            <div className="tableContainer">
              <div className="searchBoxContainer">
                <div className="riteSideContainer">
                  <input type="search" placeholder="Search" />
                  <SearchIcon />
                </div>
                <div className="leftSideContainer">
                  <button
                    onClick={() => {
                      setItem(true);
                    }}
                  >
                    <AddIcon />
                    Add Project
                  </button>
                  <MoreVertIcon />
                </div>
              </div>
              <div className="projectsearchBoxContainer">
                <div className="startDateContainer">
                  <span>From Date</span>
                  <CalendarMonthIcon />
                </div>
                <div className="endDateContainer">
                  <span>To Date</span>
                  <CalendarMonthIcon />
                </div>
                <div className="projectStates">
                  <Dropdown className="drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Project States
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="categoryDropContainer">
                  <Dropdown className="drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Category
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="projecLocationContainer">
                  <Dropdown className="drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Location
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {addItem ? (
                <></>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        />
                      </th>

                      <th>Project Name</th>
                      <th>Posted Date</th>
                      <th>Customer name</th>
                      <th>Project State</th>
                      <th>Proposal Shared</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={item.selected}
                            onChange={() => handleCheckboxChange(item.id)}
                            onClick={() => {
                              setOpenDetails(true);
                            }}
                          />
                        </td>

                        <td>{item.name}</td>
                        <td>{item.lastName}</td>
                        <td>{item.EmailID}</td>
                        <td>
                          <button id="projectStateBtn">{item.PhoneNumber}</button>
                        </td>
                        <td>{item.Rating}</td>
                        <td id="actions">
                          <RemoveRedEyeIcon />
                          {item.Actions}
                          <CreateIcon style={{ color: "#38B159" }} />{" "}
                          <DeleteForeverIcon style={{ color: "red" }} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
              )
          }
        </div>
      </div>
      
    </>
  );
}

export default Index;

// <Dropdown className="drop">
// <Dropdown.Toggle variant="success" id="dropdown-basic">
//   Location
// </Dropdown.Toggle>
