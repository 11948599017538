import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useSavePosts from "src/Hooks/useSavePosts";
import AddressForm from "../AddressForm";
import SweetAlert from "react-bootstrap-sweetalert";
import useGetPosts from "src/Hooks/useGetPosts";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
function Edit(props) {
  const {address_id} =useParams();
  const [formStatus, setFormStatus] = useState({msg:"",status:false,id:0});
  const [formdata, setFormData] = useState({
    nick_name: '',
    country: '',
    state: '',
    apartment: '',
    address: '',
    city: '',
    zipcode: ''
  });
  const {sendData:saveAddress}= useSavePosts(props.url);
  const {data:address_detail}= useGetPosts({url: "address_detail", data:{address_id: address_id}});


  let validations={
    nick_name: ['required'],
    country: ['required'],
    state: ['required'],
    apartment: ['required'],
    address: ['required'],
    city: ['required'],
    zipcode: ['required']
  }
  
  const  submitAddress=async (data) => {
    const result=await saveAddress({...data,address_id: address_id})
    result?.status==true && setFormStatus({msg: props.msg,status:true,id:result.data._id})
    setFormData(data);
  }
  useEffect(() =>{
    if(address_detail?.data){
      const address_data=address_detail.data;
      console.log('address_data::', address_data);
      setFormData(address_data)
    }

  },[address_detail])
  return (
    <>
      <SweetAlert
      success
      title={formStatus.msg}
      show={formStatus.status}
      onConfirm={()=>{ setFormStatus({msg:"",status:false});  }}
    >
      Successfully
    </SweetAlert>
      <Col xs={12} md={12}>
        <Container>
          <AddressForm defaultvalues={formdata}  validations={validations} submitAddress={submitAddress} />
        </Container>
      </Col>
    </>
  );
}

export default Edit;
