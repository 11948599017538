import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState, useRef } from "react";
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import useGetPosts from "src/Hooks/useGetPosts";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function ProfileForm({ defaultvalues, submitProfile, validations }) {
  const imageRef = useRef();
  const [formdata, setFormData] = useState({});
  const [errors, seterrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [projectImages,setProjectImages] = useState([])


  useEffect(() => {
    setFormData(defaultvalues);
  }, [defaultvalues]);

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    const temperrors = {};
    console.log("d");
    Object.keys(validations).forEach(function (field) {
      validations[field].map((value) => {
        switch (value) {
          case "required":
            console.log(formdata[field], field);
            if (!formdata[field] || formdata[field] == "") {
              temperrors[field] = field + " is required";
            }
            break;
          default:
            break;
        }
      });
    });
    
    if (Object.keys(temperrors).length != 0) {
      seterrors(temperrors);
      return;
    } else {
      seterrors({});
      console.log("no error");
    }

    
    submitProfile(formdata);
  };
  

  const handleFileRead = (event) => {
    let selected_images = []
    Object.values(event.target.files).forEach(async (value) => {
      let base64 = await convertBase64(value)
      selected_images.push(value)
      setProjectImages((prevImages) => [...prevImages, {'image':value, 'display_image':base64}])
    })
    setFormData({...formdata,['images']:selected_images})
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
       // console.log(fileReader.result);
        setSelectedImage(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  return (
    <>
      <Row>
        <Col md={12} xs={12}>
        <form>
          <Container className="project-listing-container">
            <Row>
              <Col md={12} xs={12}>
                <div className="card-lisitng">
                  <div className="project-listing-title">
                    <AccountCircleOutlinedIcon />
                    <p>Personal Details</p>
                  </div>
                </div>
              </Col>
            </Row>
            
              <Row>
                <Col md={12} sm={12}>
                  <Container fluid className="pt-4 pb-5">
                    <Row>
                    <Col md={3} className="position-relative">
                    <label htmlFor="profileImageInput" className="profile-image-label">
                    <img
                        className="rounded-circle"
                        style={{ width: "200px", height: "200px" }}
                        src={selectedImage ? selectedImage : `${process.env.REACT_APP_URL}/uploads/${formdata?.profile_image?.[0]}`}
                        alt="Profile"
                      />

                      <MDBBtn floating size="" tag="a" className="position-absolute bottom-0 end-0">
                        <MDBIcon fas icon="pen" />
                      </MDBBtn>
                    </label>
                    <input
                      id="profileImageInput"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileRead(e)}
                    />
                  </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={6}>
                            <div className="city-dropdown  streetfeild">
                              <label className="fw-bold">First Name.</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formdata?.first_name}
                                name="first_name"
                                placeholder="Enter First Name"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="city-dropdown  streetfeild">
                              <label className="fw-bold">Last Name.</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formdata?.last_name}
                                name="last_name"
                                placeholder="Enter Last Name"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="city-dropdown  streetfeild">
                              <label className="fw-bold">Email Name.</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formdata?.email}
                                name="email"
                                readOnly
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="city-dropdown  streetfeild">
                              <label className="fw-bold">Contact Number.</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formdata?.contact_no}
                                name="contact_no"
                                placeholder="(00) 00000 00000"
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            <Container className="project-listing-container">  
              <Row>
                <Col md={12} xs={12}>
                  <div className="card-lisitng">
                    <div className="project-listing-title">
                      <ArticleOutlinedIcon />
                      <p>Description</p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} sm={12}>
                  <Container fluid className="pt-4 pb-5">
                    <Row>
                      <Col md={12}>
                        <div
                          className="city-dropdown streetfeild"
                          
                        >
                          <label className="fw-bold">Introduce Yourself (optional)</label>
                          <textarea
                              type="text"
                              className="form-control"
                              name="description"
                              placeholder="Enter First Name"
                              rows="4"
                              onChange={(e) => handleChange(e)}
                              value={formdata?.description || ''} // Use the value attribute
                            />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  </Col>
              </Row>
              
            
          </Container>
          </form>
        </Col>
      </Row>
      <button
        className="saveChanges"
        onClick={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        Save Changes
      </button>
    </>
  );
}
export default ProfileForm;
