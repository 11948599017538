import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useGetPosts from "./useGetPosts";
import { useDispatch } from "react-redux";
const useProposalsActions = (url, data) => {
    const dispatch=useDispatch();
    //const {getdata}=useGetPosts({url: 'message_list'});
    const {getdata}=useGetPosts({url: url, data: data, onLoad: "false"});

    const setProposal = async (proposal_id, type) => {
        const proposaldetails=await getdata({proposal_id: proposal_id});
        console.log(proposaldetails,'propo,dispatch');
        if(type === 'customer') {
            proposaldetails?.data?.vendors.map((value,index) =>{
                if(proposal_id==value._id){
                    dispatch({ type: "GET_PROPOSAL_DETAIL", payload: value })
                }
                return null;
            })
        }

        if(type === 'vendor') {
            dispatch({ type: "GET_PROPOSAL_DETAIL", payload: proposaldetails?.data });
        }
        
    }
    return {setProposal};
};

export default useProposalsActions;