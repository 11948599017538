import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState,useRef } from "react";
import useGetPosts from "src/Hooks/useGetPosts";
import { usStateNames } from "src/Utlilities/us_states";

function useForceUpdate(){
  const [value, setValue] = useState(0); 
  return () => setValue(value => value + 1); 
}

function ProjectForm({defaultvalues,submitProject,validations}) {

  const imageRef = useRef();
  const [formdata, setFormData] = useState({});
  const [projectImages,setProjectImages] = useState([])
  const {data:categories}= useGetPosts({url:"project_categories"});
  const [errors, seterrors] = useState({});
  const [keepImages, setKeepImages] = useState([])
  const forceUpdate = useForceUpdate();

  const showOpenFileDlg = (e) => {
    e.preventDefault();
    imageRef.current.click()
  }

  const handleSubmit = (e) =>{
    let image_ids = '' 
    const temperrors={};
    Object.keys(validations).forEach(function(field) {
      validations[field].map((value) => {
          switch(value){
            case "required":
              if(!formdata[field] || formdata[field]==''){
                temperrors[field]=field+" is required";
              }
              break;
            default:
              break;
          }
        })
      })
      if(Object.keys(temperrors).length!=0){
        seterrors(temperrors);
        return;
      } else{
        seterrors({});
      }
      if(keepImages.length>0){
       
        const idArray = keepImages.map(obj => obj.id);
        image_ids = idArray.join(',');

      }
   
      submitProject(formdata, image_ids)
  }

  const removeOldImage=(value) =>{
    let old = keepImages
    let oldIndex = old.indexOf(value)
    if(oldIndex>-1){
      old.splice(oldIndex,1);
    }
    setKeepImages(old)

    let tempimages=projectImages
    let index = tempimages.indexOf(value)
    if(index>-1){
      tempimages.splice(index,1);
    }
    setProjectImages(tempimages)
    forceUpdate()
  }
  const removeImage=(e,value)=>{
    if(value.hasOwnProperty('id')){
      removeOldImage(value)
    }
    else{
      let tempimages=formdata.images
      const index = tempimages.indexOf(value['image']);
      if (index > -1) { // only splice array when item is found
        tempimages.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFormData({...formdata,['images']:tempimages})
      let display_images = [...projectImages]
      let left_images = display_images.filter((item) => item !== value);
      setProjectImages(left_images)
    }
  }

  const handleFileRead = (event) => {
    let selected_images = []
    Object.values(event.target.files).forEach(async (value) => {
      let base64 = await convertBase64(value)
      selected_images.push(value)
      setProjectImages((prevImages) => [...prevImages, {'image':value, 'display_image':base64}])
    })
    setFormData({...formdata,['images']:selected_images})
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(()=>{
    setFormData(defaultvalues)
    let oldImages = [];   
    defaultvalues?.images?.map(async (item)=> {
       oldImages.push({'display_image':item.file, 'id':item._id});
    })
    setProjectImages(oldImages)
    setKeepImages(oldImages)
  },[defaultvalues])


  return (
  <Container>
    <form className="customer_add_form">
      <Row>
        <Col md={4} sm={6} lg={4}>
          <label>Title:</label>
          <input type="text" name="title" placeholder="Title" value={formdata?.title}  onChange={(e) => setFormData({...formdata,["title"]:e.target.value })}/>
          { errors?.title && (<div className='error'>{errors?.title}</div>) }
        </Col>
        <Col md={4} sm={6} lg={4}>
          <label>Project Type:</label>
          <select name='category' value={formdata?.category} onChange={(e) => setFormData({...formdata,["category"]:e.target.value })}>
                    <option>Please select </option>

              {categories?.data?.map((cat) => (
               <> 
                  <option key={cat._id} value={cat._id}>
                  {cat.title}
                </option>
               </>
                
              ))}
            </select>  
            { errors?.category && (<div className='error'>{errors?.category}</div>) }                    
        </Col>
       
       { /* <Col md={4} sm={6} lg={4}>
          <label>Category:</label>
          <select name='sub_category' value={formdata?.sub_category} onChange={(e) => setFormData({...formdata,["sub_category"]:e.target.value })}>
              <option>Please select </option>

              {categories?.data?.map((cat) => (
                <>
                 <option key={cat._id} value={cat._id}>
                  {cat.title}
                </option>
                 </>
             
              ))}
            </select>    
            { errors?.sub_category && (<div className='error'> Sub-category is required </div>) }                  
        </Col> 
        <Col md={4} sm={6} lg={4}>
          <label>Street Name:</label>
          <input type="text" name="street_name" placeholder="Street Name" value={formdata?.street_name}  onChange={(e) => setFormData({...formdata,["street_name"]:e.target.value })}/>
          { errors?.street_name && (<div className='error'>Street name is required</div>) }      
        </Col>*/}
        <Col md={4} sm={6} lg={4}>
          <label>Address:</label>
          <input type="text" name="address" placeholder="location" value={formdata?.location}  onChange={(e) => setFormData({...formdata,["location"]:e.target.value })}/>
          { errors?.location && (<div className='error'>{errors?.location}</div>) }      
        </Col>
        <Col md={4} sm={6} lg={4}>
          <label>Unit/Apt:</label>
          <input type="text" name="unit" placeholder="unit" value={formdata?.unit}  onChange={(e) => setFormData({...formdata,["unit"]:e.target.value })}/>
          { errors?.unit && (<div className='error'>{errors?.unit}</div>) }     
        </Col>
        <Col md={4} sm={6} lg={4}>
          <label>City:</label>
          <input type="text" name="city" placeholder="city" value={formdata?.city}  onChange={(e) => setFormData({...formdata,["city"]:e.target.value })}/>
          { errors?.city && (<div className='error'>{errors?.city}</div>) }     
        </Col>
        <Col md={4} sm={6} lg={4}>
          <label>State:</label>
          <select name='state' value={formdata?.state} onChange={(e) => setFormData({...formdata,["state"]:e.target.value })}>
            <option>Please select </option>

              {usStateNames.map((state, index) => (
               <> 
                   <option key={index} value={state}>
                  {state}
                  </option>
               </>
               
              ))}
            </select>    
            { errors?.state && (<div className='error'>{errors?.state}</div>) }                  
        </Col>


        <Col md={4} sm={6} lg={4}>
          <label>Zipcode:</label>
          <input type="text" name="zipcode" placeholder="Zipcode" value={formdata?.zipcode}  onChange={(e) => setFormData({...formdata,["zipcode"]:e.target.value })}/>
          { errors?.zipcode && (<div className='error'>{errors?.zipcode}</div>) }      
        </Col>
        <Col md={12} sm={12} lg={12}>
          <label>Description:</label>
          <input
            type="textarea"
            name="description"
            placeholder="Description"
            value={formdata?.description} 
            onChange={(e) => setFormData({...formdata,["description"]:e.target.value })}
          />
          { errors?.description && (<div className='error'>{errors?.description}</div>) }
        </Col>
        <Col md={12} sm={12} lg={12}>
        <div>
          <div className="d-flex flex-row">
            {projectImages?.map((value,key)=>{
                return(
                  <div key={key}>
                    <img style={{width:"50px", height:"50px"}} src={value['display_image']}/>
                    <a onClick={(e) => removeImage(e,value)}>X</a>
                  </div>
                )
              })} 
            </div>
            <input ref={imageRef} onChange={(e) => handleFileRead(e)} type="file" multiple style={{ display: "none" }}/>
            <button onClick={showOpenFileDlg}>Upload Image</button>
        
        </div>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <button
            className="btn"
            type="submit"
            onClick={(e) => {  e.preventDefault(); handleSubmit(e) }}
          >
            Submit
          </button>
        </Col>
      </Row>
    </form>
  </Container>
             
  );
}

export default ProjectForm;
