import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Slider from "@mui/material/Slider";
import SearchIcon from "@mui/icons-material/Search";
import useFetchPost from "../../../Hooks/useFetchPost";
import ProjectCard from "../components/ProjectCard";
// import useFetch from "../../../Hooks/useFetch";
import Wrapper from "../../../Utlilities/Wrapper";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BsSearch } from "react-icons/bs";
import useGetPosts from "../../../Hooks/useGetPosts";



function ProjectList() {
  const [time, setTime] = useState("4 hrs");
  const wrapperHeight = "14vh";
  const [value, setValue] = React.useState([0, 100]);

  const [params, setParams] = useState("");
  const [changeLocation, setChangeLocation] = useState("");

  const [selectedCategories, setSelectedCategories] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotaltPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [selectedSortOption, setSelectedSortOption] = useState("New");
  const [orderBy, setOrderTitle] = useState("New");

  const [position, setPosition] = useState({ latitude: null, longitude: null });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChange = (e) => {
    setParams(e.target.value);
  };
  const handleChangeLocation = (e) => {
    setChangeLocation(e.target.value);
  };
  var search = {    
    s: params,
    location: changeLocation,    
    page:currentPage,
    category:selectedCategories,
    lat:position.latitude,
    long:position.longitude,
    orderBy:orderBy,
  };
  var body = JSON.stringify(search);
  
  const handleCategorySelect = (categoryTitle) => {
    const isSelected = selectedCategories.includes(categoryTitle);
  
    if (isSelected) {
      // If already selected, remove from the list
      setSelectedCategories(selectedCategories.filter((title) => title !== categoryTitle));
    } else {
      // If not selected, add to the list
      setSelectedCategories([...selectedCategories, categoryTitle]);
    }
  };

  
  const handleSortByDropdownItemClick = (key) => {
      switch (key) {
        case "new_project":
          setOrderTitle("New");
          setSelectedSortOption('New');
          break;
        case "old_project":
          setOrderTitle("Old");
          setSelectedSortOption('Old');
          break;
        case "a_to_z":
          setOrderTitle("A to Z");
          setSelectedSortOption('A to Z');
          break;
        case "z_to_a":
          setOrderTitle("Z to A");
          setSelectedSortOption('Z to A');
          break;        
        case "lat_asc":
            setOrderTitle("Distance:Nearest");
            setSelectedSortOption('Distance:Nearest');
            break;
        case "lat_desc":
            setOrderTitle("Distance:Farthest");
            setSelectedSortOption('Distance:Farthest');
            break;
        case "rating_desc":
            setOrderTitle("Highest Rating");
            setSelectedSortOption('Highest Rating');
            break;
        case "rating_asc":
            setOrderTitle("Lowest Rating");
            setSelectedSortOption('Lowest Rating');
            break;                
        default:
          // Handle default case if needed
          break;
      }
    };





  const { getdata, data: projects, totPages: totalDataPages } = useGetPosts({
    url: 'front_project',
    data: search,
  });

  useEffect(() => {
    getdata();
  }, [params, changeLocation, currentPage,selectedCategories,orderBy]);

  // Log the value of totalDataPages whenever it changes
  useEffect(() => {
    // Set loading to false once the data is available
    setLoading(false);
  }, [totalDataPages]);

  // Set the value of totPages to the state variable setTotaltPage
  useEffect(() => {
    setTotaltPage(totalDataPages);
  }, [totalDataPages]);

  
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);
  
  return (
    <>
      <Wrapper wrapperHeight={wrapperHeight} />
      <Container className="">
        <Row>
          <Col sm={3} md={3} xs={3}>
            <Container>
            <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 select-category-div">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Category
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "none",
                          background: "#FFFFFF",
                          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.08)",
                          borderRadius: "5px",
                        }}
                      >                        
                        <span>{selectedCategories.length > 0 ? selectedCategories.join(', ') : 'Category'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      {projects?.category.map((categoryList) => (
                        <Dropdown.Item
                          key={categoryList.title}
                          onClick={() => handleCategorySelect(categoryList.title)}
                        >
                          {categoryList.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>

                    </Dropdown>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 filterContainer">
                    <p>Filters</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} xs={12}>
                <div className="my-3">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Location
                    </p>
                    <div
                      className="search"
                      style={{
                        display: "flex",
                        justifyContent: "spaceBetween",
                        flexDirection: "revert",
                        alignItems: "center",
                        padding: "0px 10px 0px 10px",
                        height: "48px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control z-depth-1"
                        placeholder="Location"
                        onChange={(e) => handleChangeLocation(e)}
                        style={{
                          textIndent: "0px",
                          outline: "none",
                          border: "none",
                          minHeight: "100%",
                        }}
                      />
                      <LocationOnIcon />
                    </div>
                  </div>
                </Col>
              </Row>
              {/*
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 skills-buttons-div">
                    <p>Duration</p>
                    <div>
                      <input type="text" placeholder="Duration" />
                      <AddBoxIcon />
                    </div>
                  </div>
                </Col>
              </Row>
              
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 rangeContainer">
                    <p>Price Range</p>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      classes={".sliderContainer"}
                      style={{
                        color: "#6A2FF9",
                        height: "2px",
                        marginLeft: "10px",
                        width: "215px",
                      }}
                    />
                  </div>
                </Col>
              </Row> */ }
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <div className="my-3 select-category-div">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Sort By
                    </p>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "none",
                          background: "#FFFFFF",
                          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.08)",
                          borderRadius: "5px",
                        }}
                      >
                        
                        <span>{selectedSortOption ? `Sort By: ${selectedSortOption}` : 'Sort By'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>        
                          <Dropdown.Item key="new_preject"   onClick={() => handleSortByDropdownItemClick("new_project")}>
                            By Date : New Project
                          </Dropdown.Item>          
                          <Dropdown.Item key="old_preject"  onClick={() => handleSortByDropdownItemClick("old_project")}>
                            By Date : Old Project
                          </Dropdown.Item>   
                          <Dropdown.Item key="a_to_z"  onClick={() => handleSortByDropdownItemClick("a_to_z")}>
                            Project Name : A to Z
                          </Dropdown.Item>
                          <Dropdown.Item key="z_to_a"  onClick={() => handleSortByDropdownItemClick("z_to_a")}>
                            Project Name : Z to A
                          </Dropdown.Item>    
                          <Dropdown.Item key="lat_asc"  onClick={() => handleSortByDropdownItemClick("lat_asc")}>
                            Distance:Nearest
                          </Dropdown.Item> 
                          <Dropdown.Item key="lat_desc"  onClick={() => handleSortByDropdownItemClick("lat_desc")}>
                            Distance:Farthest
                          </Dropdown.Item>                           
                          <Dropdown.Item key="rating_desc"  onClick={() => handleSortByDropdownItemClick("rating_desc")}>
                            Highest Rating
                          </Dropdown.Item> 
                          <Dropdown.Item key="rating_asc"  onClick={() => handleSortByDropdownItemClick("rating_asc")}>
                            Lowest Rating
                          </Dropdown.Item>                           

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row> 
             {/* <Row>
                <Col md={12} sm={12} xs={12} className="tagsContainer">
                  <p>Tags</p>
                  <div className="my-3 skills-buttons-div">
                    <button>Plumbing</button>
                    <button>Electrician</button>
                    <button>Carpenter</button>
                    <button>Carpenter</button>
                  </div>
                </Col>
              </Row> */}
            </Container>
          </Col>
          <Col md={9} sm={9} xs={9}>
            <Container>
              <Row>
                <Col md={12} sm={12} xs={12}>
                <div className="search-panel-service">
                    <p
                      style={{
                        fontFamily: "Nunito Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        lineHeight: "27px",
                        color: "#3D3939",
                      }}
                    >
                      Search List
                    </p>
                    <span>{<BsSearch />}</span>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Search by relevance"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => handleChange(e)}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              
              <Row>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {projects?.data?.map((project) => (
                      <ProjectCard
                      key={project.id}
                      title={project.title}
                      description={project.description}
                      location={project.location}
                      time={time}
                      budget={project.budget}
                      datePosted={project.created_at}
                      id={project._id}
                      is_immidieat={project.is_immediate}
                      street_name={project.street_name}
                      created_at={project.created_at}
                      images={project.images}
                    />
                    ))}
                    <div>
                      <p>Page {currentPage} of {projects?.totPages}</p>
                      <button className="btn btn-primary custom-btn-geegr"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous Page
                      </button>&nbsp;&nbsp;&nbsp;
                      <button className="btn btn-primary custom-btn-geegr"
                        disabled={currentPage === projects?.totPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next Page
                      </button>
                    </div>
                  </>
                )}

                  
              </Row>


            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProjectList;
