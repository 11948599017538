import React from "react";
import ReactDOM from "react-dom/client";

import { GoogleOAuthProvider } from '@react-oauth/google';

import "./index.scss";
import App from "./App";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
//context provider
// import { ThemeProvider } from "./Context/ThemeContext";
// import { AuthProvider } from "./Context/AuthContext";
//redux setup
import configureStore from "./redux/configureStore";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));

// Load environment variables

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>  
  <React.StrictMode>
    <Provider store={configureStore}>
      <App />
    </Provider>
  </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
