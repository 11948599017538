import React, { useState, useEffect } from "react";
import { MDBAccordion, MDBAccordionItem, MDBBadge  } from 'mdb-react-ui-kit';
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import "./Accordion.scss";


const Accordion = (props) => {
    const type = props.type??'project';

    console.log(type);

    return (
        <MDBAccordion initialActive={1}>

            <MDBAccordionItem collapseId={1} headerTitle={
            <>
            <span className="span-title">Need a carpenter</span>
            <span class="text-muted ms-3">5 hours ago</span>
            <MDBBadge pill light className="ms-3 float-end">New Service Request</MDBBadge>
            </>}>
                <Row md={12}>
                    <Col md={6}>
                        <p className="fw-bold fs-6">Assigned To</p>
                        <p className="fw-bold fs-6">
                        <img
                            src='https://mdbootstrap.com/img/new/standard/city/047.webp'
                            className='avatar rounded-circle'
                            alt=''
                            /> &nbsp;
                              Tom Smith
                        </p>
                        
                    </Col>
                    <Col md={6} className="position-relative">
                    <p className="fw-bold fs-6"></p>
                        <Button className="position-absolute bottom-0 end-0" variant="link">Check Now</Button>
                    </Col>
                </Row>
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={2} headerTitle={
            <>
            <span className="span-title">Need a electrician</span>
            <span class="text-muted ms-3">5 hours ago</span>
            <MDBBadge pill color='success' light className="ms-3">Project Completed</MDBBadge></>}>
            <Row md={12}>
                    <Col md={6}>
                        <p className="fw-bold fs-6">Assigned To</p>
                        <p className="fw-bold fs-6">
                        <img
                            src='https://mdbootstrap.com/img/new/standard/city/047.webp'
                            className='avatar rounded-circle'
                            alt=''
                            /> &nbsp;
                              Tom Smith
                        </p>
                        
                    </Col>
                    <Col md={6} className="position-relative">
                    <p className="fw-bold fs-6"></p>
                        <Button className="position-absolute bottom-0 end-0" variant="link">Check Now</Button>
                    </Col>
                </Row>
            </MDBAccordionItem>

            <MDBAccordionItem collapseId={3} headerTitle={
            <>
            <span className="span-title">Need a dog runner</span>
            <span class="text-muted ms-3">5 hours ago</span>
            <MDBBadge pill color='warning' light className="ms-3">28 New Proposal</MDBBadge></>}>
            <Row md={12}>
                    <Col md={6}>
                        <p className="fw-bold fs-6">Assigned To</p>
                        <p className="fw-bold fs-6">
                        <img
                            src='https://mdbootstrap.com/img/new/standard/city/047.webp'
                            className='avatar rounded-circle'
                            alt=''
                            /> &nbsp;
                              Tom Smith
                        </p>
                        
                    </Col>
                    <Col md={6}>
                    <p className="fw-bold fs-6"></p>
                        <Button className="float-end" variant="link">Link</Button>
                    </Col>
                </Row>
            </MDBAccordionItem>

        </MDBAccordion>
    );
}

export default Accordion;