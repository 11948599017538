import React, { useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useState,useRef } from "react";
import useGetPosts from "src/Hooks/useGetPosts";
function Form({defaultvalues,submitService,validations}) {

  const imageRef = useRef();
  const [formdata, setFormData] = useState({});
  const [errors, seterrors] = useState({});
  const [projectImages,setProjectImages] = useState([])



  const employeeRoles = [{ role: 'Manager', id: 1 },{ role: 'Operator', id: 2 }];

  console.log(employeeRoles);

  const showOpenFileDlg = (e) => {
    e.preventDefault();
    imageRef.current.click()
  }

  const handleSubmit = (e) =>{
    const temperrors={};
    console.log("d");
    Object.keys(validations).forEach(function(field) {
      validations[field].map((value) => {
          switch(value){
            case "required":
              console.log(formdata[field],"validation");
              if(!formdata[field] || formdata[field]==''){
                temperrors[field]=field+" is required";
              }
              break;
            default:
              break;
          }
        })
      })
      if(Object.keys(temperrors).length!=0){
        seterrors(temperrors);
        return;
      } else{
        seterrors({});
        console.log("no error")
      }
      submitService(formdata)
  }

   const removeImage=(e,value)=>{
    if(value.hasOwnProperty('id')){
      removeOldImage(value)
    }
    else{
      let tempimages=formdata.images
      const index = tempimages.indexOf(value['image']);
      if (index > -1) { // only splice array when item is found
        tempimages.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFormData({...formdata,['images']:tempimages})
      let display_images = [...projectImages]
      let left_images = display_images.filter((item) => item !== value);
      setProjectImages(left_images)
    }
  }

  const removeOldImage=(e,value) =>{
    setFormData({...formdata,removedImages:[...formdata.removedImages,value]})
    
    let tempimages=formdata.oldImages
    console.log(tempimages,value);
    const index = tempimages.indexOf(value);
    console.log(index);
    if (index > -1) { // only splice array when item is found
      tempimages.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({...formdata,oldImages:tempimages})

    setFormData({...formdata,removedImages:[...formdata.removedImages,value]})
  }
  const removeNewImage=(e,value)=>{
    let tempimages=formdata.images
    console.log(tempimages,value);
    const index = tempimages.indexOf(value);
    console.log(index);
    if (index > -1) { // only splice array when item is found
      tempimages.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({...formdata,images:tempimages})
  }
  const handleFileRead = async (event) => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setFormData({...formdata,images:[...formdata.images,base64]})
    console.log(formdata.images)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  useEffect(()=>{
    setFormData(defaultvalues)
  },[defaultvalues])


  return (
  <Container>
    <form className="customer_add_form">
      <Row>
        <Col md={6} sm={6} lg={6}>
          <label>EIN:</label>
          <input type="text" name="ein" placeholder="EIN" value={formdata?.ein}  onChange={(e) => setFormData({...formdata,["ein"]:e.target.value })}/>
          { errors?.ein && (<div className='error'>{errors?.ein}</div>) }
        </Col>
        <Col md={6} sm={6} lg={6}>
           <label>Role:</label>
          <select name='role' value={formdata?.role} onChange={(e) => setFormData({...formdata,["role"]:e.target.value })}>
                    <option>Please select </option>

              {employeeRoles?.map((emprole) => (
               <> 
                  <option key={emprole.id} value={emprole.id}>
                  {emprole.role}
                </option>
               </>
                
              ))}
            </select>  
            { errors?.role && (<div className='error'>{errors?.role}</div>) }    
        </Col>
        <Col md={6} sm={6} lg={6}>
          <label>EIN:</label>
          <input type="text" name="name" placeholder="Name" value={formdata?.name}  onChange={(e) => setFormData({...formdata,["name"]:e.target.value })}/>
          { errors?.name && (<div className='error'>{errors?.name}</div>) }
        </Col>
        <Col md={6} sm={6} lg={6}>
          <label>EIN:</label>
          <input type="text" name="email" placeholder="Email" value={formdata?.email}  onChange={(e) => setFormData({...formdata,["email"]:e.target.value })}/>
          { errors?.email && (<div className='error'>{errors?.email}</div>) }
        </Col>
        <Col md={6} sm={6} lg={6}>
          <label>Username:</label>
         <input type="text" name="username" placeholder="Username" value={formdata?.username}  onChange={(e) => setFormData({...formdata,["username"]:e.target.value })}/>
          { errors?.username && (<div className='error'>{errors?.username}</div>) }            
        </Col>
        <Col md={6} sm={6} lg={6}>
          <label>Password:</label>
          <input type="password" name="password" placeholder="Password" value={formdata?.password}  onChange={(e) => setFormData({...formdata,["password"]:e.target.value })}/>
          { errors?.password && (<div className='error'>{errors?.password}</div>) }               
        </Col>
        
        <Col md={3} sm={3} lg={3}>
        <div>
           <div className="d-flex flex-row">
            {projectImages?.map((value,key)=>{
                return(
                  <div key={key}>
                    <img style={{width:"50px", height:"50px"}} src={value['display_image']}/>
                    <a onClick={(e) => removeImage(e,value)}>X</a>
                  </div>
                )
              })} 
            </div>
            <input ref={imageRef} onChange={(e) => handleFileRead(e)} type="file" multiple style={{ display: "none" }}/>
            <button onClick={showOpenFileDlg}>Upload Passport</button>        
        </div>
        </Col>
         <Col md={1} sm={1} lg={1}>
        <div>
           <div className="d-flex flex-row"> OR
           </div>
         </div>
        </Col>   

        <Col md={3} sm={3} lg={3}>
        <div>
           <div className="d-flex flex-row"> 
            {projectImages?.map((value,key)=>{
                return(
                  <div key={key}>
                    <img style={{width:"50px", height:"50px"}} src={value['display_image']}/>
                    <a onClick={(e) => removeImage(e,value)}>X</a>
                  </div>
                )
              })} 
            </div>
            <input ref={imageRef} onChange={(e) => handleFileRead(e)} type="file" multiple style={{ display: "none" }}/>
            <button onClick={showOpenFileDlg}>Upload Driving Licence</button>        
        </div>

        </Col>
    </Row>
    <Row>
        <Col md={6} sm={6} lg={6}>
          <Button
            variant="primary" size="lg"
            onClick={(e) => {e.preventDefault(); handleSubmit(e)}}
          >
            Add Employee
          </Button>
        </Col>
      </Row>
    </form>
  </Container>
             
  );
}

export default Form;
