import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendIcon from '@mui/icons-material/Send';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ChatProposal from 'src/Screens/Dashboard/Customer/Chat/Components/ChatProposal';
import UpdateProposalRequest from "./UpdateProposalRequest"
import useSavePosts from "src/Hooks/useSavePosts";
import ChatLineItems from "./ChatLineItems";
import CustomFile from "./CustomFile";

import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useEffect } from "react";
import PaymentModel from "../../Components/Payment/PaymentModel";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import useSendFormData from "src/Hooks/useSendFormData";

const ChatBody = () => {
    let { id } = useParams();
    const getState = useSelector((state) => state);
    const [confirmStatus,setConfirmStatus] = useState({msg:"",status:false});

    const [inputs,setInput] = useState({proposal_id:id});

    const [modalState,setModalState]=useState(false)
    const [paymentModalState,setPaymentModalState]=useState(false)

    const [line_items,setLineItems]=useState([])
    const [paymentRequestData,setPaymentRequestData]=useState({})
    const hiddenFileInput = useRef(null);
    const {sendData}=useSavePosts('accept_proposal')
    const {sendForm:sendFile}= useSendFormData("customer_send_file");
    const {sendData:sendMessage}=useSavePosts('send_message')

    
    async function SendMessage(){
        if(inputs?.files?.length>0){
            let formData = new FormData();
            formData.append('proposal_id', inputs.proposal_id)
            inputs.files.map((file)=>{
                formData.append('file',file)
            })
            const result = await sendFile(formData)
            if(result){
                setConfirmStatus({msg:"Files Sent!!",status:true})
            }
            setInput({...inputs,files:''})
        }
        else{
            await sendMessage(inputs)
            setInput({...inputs,message:""})
            document.getElementById('chatboxid').scrollTo(0, document.getElementById('chatboxid').scrollHeight);
        }
    }
    async function updateProposalRequest(updated_line_items){ 
        setModalState(false) 
        setPaymentRequestData({proposal_id: id,line_items:updated_line_items,reqest_for: 'accept_project'})
        setPaymentModalState(true);
    }
    const PaymentDone = async (response) =>{
        if(response.status){
            sendData({proposal_id:id,line_items:response.data.line_items}); 
            setConfirmStatus({msg:"Payment Done, Project Started successfully",status:true})
        }
    }

    const selectFile = ()=>{
        hiddenFileInput.current.click();
    }

    const handlFile = (e)=>{
        let selected_files = inputs?.files?.length>0?inputs?.files:[]
            Object.values(e.target.files).forEach(async (value) => {
                selected_files.push(value)
            })
        setInput({...inputs, files:selected_files})
    }
    const removeFile=(e)=>{
        setInput({...inputs, files:''})
    }

    useEffect(() => {
        setInput({...inputs,proposal_id:id})
        setModalState(false)
    },[id])
    return (<>
        <div className="chatbody" id="chatboxid">
        <SweetAlert
            success
            title={confirmStatus.msg}
            show={confirmStatus.status}
            onConfirm={()=>{ setConfirmStatus({msg:"",status:false});  }}
        >
            Successfully
        </SweetAlert>
        <UpdateProposalRequest 
            proposal_show={modalState} 
            onOutSideClick={() => setModalState(false)}  
            onSendData={(updated_line_items) => updateProposalRequest(updated_line_items) }  
            line_items={line_items} 
        />
        <PaymentModel 
            payment_show={paymentModalState} 
            request_data={paymentRequestData} 
            onOutSideClick={() => setPaymentModalState(false)}
            onPaymentDone={(response) => PaymentDone(response)}
        />
        {
            getState?.NewChats?.messages?.map((value,index)=>{
                return (
                    <div  className={"chatbox message-"+ value.type +" message-"+value.from}>
                       
                        {console.log(value.is_actionable,'chatcheck')}

                        {value.type!='system' &&
                        <img  
                               src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                              className="rounded-circle chatimg"
                              
                            />
                        }
                        {value.type=='message' && (<div className="chat"><p>{value.message}</p></div>)}
                        {value.type=='proposal_update' && (<ChatProposal message={value} onPropsalRequest={({inline_items}) => { console.log(inline_items,'dfr'); setModalState(true);  setLineItems(inline_items) } } />) }
                        {value.type=='add_line_items' && (<ChatLineItems message={value} onPropsalRequest={({inline_items}) => { } } />) }
                        {value.type=='system' && (<div className="chat chatcenter system"><p>{value.message}</p></div>) }
                        {value.type=='file' && (<div className="chat chatcenter system"><CustomFile message={value} /></div>) }  
                    </div>
                    
                )
            
            })
        }
        </div>
        {/* <div className="chatinput">
            <textarea name="message" placeholder="type text here...." onKeyUp={(e) => {  (e.key == "Enter" && !e.shiftKey) && SendMessage() }} onChange={(e) => setInput({...inputs,message:e.target.value })} value={inputs?.message} />
            <button className="btn btn-info  blue" >Start Call</button> <button className="btn btn-info white" >Contact Admin</button>
        </div> */}

        <div className="chatinput-2 mt-5"><div className="input-group p-1">
                <input  className='form-control' name="message" placeholder="type text here...." onKeyUp={(e) => {(e.key == "Enter" && !e.shiftKey) && SendMessage() }} onChange={(e) => setInput({...inputs,message:e.target.value })} value={inputs?.message} />
                <input type="file" hidden ref={hiddenFileInput} onChange={(e)=>handlFile(e)}/>
                <button  className="btn btn-outline-secondary attachBtn" onClick={selectFile}>
                    {inputs?.files?.length>0
                        ?<div>
                            <p>{inputs?.files.length} files selected </p>
                        </div>
                        :<AttachFileOutlinedIcon />
                    }
                </button>
                {inputs?.files?.length>0 &&
                    <button onClick={(e) => removeFile(e)} className="remove-file-button"><p>X</p></button>
                }
                <button className="btn btn-secondary sendBt" type="button" id="button-addon2" onClick={SendMessage}><SendIcon /></button>
            </div>
        </div>
        
            <br />

    </>)
}

export default ChatBody;
