import React from "react";
import { Rating } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
function Riewe() {
  return (
    <div className="rieweContainer">
      <h1>All Reviews</h1>
      <div className="ratingCardContainer">
        <div className="ratingCard">
          <span>Airframe and power plant</span>
          <div className="countContainer">
            <div className="starCount">4.0</div>
            <Rating name="no-value" value="4" readOnly color="#faaf00" />
            <div className="calendarContainer">
              <CalendarMonthIcon />
              <span>August 2019</span>
            </div>
          </div>
          <span id="disamble">
            Disassemble, repair and reassemple aircraft engins.
          </span>
        </div>
        <div className="ratingCard">
          <span>Airframe and power plant</span>
          <div className="countContainer">
            <div className="starCount">4.0</div>
            <Rating name="no-value" value="4" readOnly color="#faaf00" />
            <div className="calendarContainer">
              <CalendarMonthIcon />
              <span>August 2019</span>
            </div>
          </div>
          <span id="disamble">
            Disassemble, repair and reassemple aircraft engins.
          </span>
        </div>
        <div className="ratingCard">
          <span>Airframe and power plant</span>
          <div className="countContainer">
            <div className="starCount">4.0</div>
            <Rating name="no-value" value="4" readOnly color="#faaf00" />
            <div className="calendarContainer">
              <CalendarMonthIcon />
              <span>August 2019</span>
            </div>
          </div>
          <span id="disamble">
            Disassemble, repair and reassemple aircraft engins.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Riewe;
