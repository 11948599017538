import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VendorWrapper from "../VendorWrapper";
import VendorProfile from "./VendorProfile";
import Address from "./Address/Address";
import Payment from "./Payment";
import ChangePassword from "./ChangePassword";
import { PayPalScriptProvider} from '@paypal/react-paypal-js';

function VendorProfileTabs() {
  const [fillActive, setFillActive] = useState("profile");
  const {tab} = useParams();

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  useEffect(() => {
    setFillActive(tab??'profile');
    console.log('TABS:', fillActive);
  }, [tab]);

  const crumbs = [
    {tab: "profile", menu: "Profile", component: <VendorProfile />, icon: <ArticleOutlinedIcon />},
    {tab: "address", menu: "Address", component: <Address />, icon: <LocationOnOutlinedIcon />},
    {tab: "payment", menu: "Payment", component: <Payment />, icon: <BusinessCenterOutlinedIcon />},
    {tab: "change_password", menu: "Change Password", component: <ChangePassword />, icon: <LockOutlinedIcon />}
  ];

  return (
    <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
    <VendorWrapper>
      
      <MDBTabs className="mb-3">        
        {crumbs.map((data, i) => {
            return (
                <MDBTabsItem key={i}>
                    <Link to={`/vendor/profile/${data.tab}`} style={{ textDecoration: "none"}} key={i}>
                        <MDBTabsLink active={fillActive === data.tab} key={i}>{data.icon} {data.menu}</MDBTabsLink>
                    </Link>
                </MDBTabsItem>
            )
        })}
      </MDBTabs>
      
      <MDBTabsContent>
        {crumbs.map((data, i) => {
            return (
                <MDBTabsPane show={fillActive === data.tab} key={i}>{data.component}</MDBTabsPane>
            )
        })}
      </MDBTabsContent>
    </VendorWrapper>
    </PayPalScriptProvider>
  );
}

export default VendorProfileTabs;
