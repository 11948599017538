import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Rating } from "@mui/material";

const AboutVendor = () => {
    const {NewChats:{proposal}} = useSelector((state) => state);

    return (
        <div className="project-vendor-details">
            <h5>{proposal?.service?.vendor.first_name}</h5>
            <Rating name="no-value" value="4" readOnly />
            <table>
                <tr>
                    <th>Project Completed</th>
                    <th>Location</th>
                </tr>
                <tr>
                    <td>12</td>
                    <td>13</td>
                </tr>
            </table>
            <a>View Profile</a>
        </div>
    )
}
export default AboutVendor;