import React, {useState, useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import {
    MDBCollapse,
    MDBBtn,
    MDBBadge,
  } from 'mdb-react-ui-kit';

import Sidebar from "../Components/Sidebar/Sidebar";
import "src/Screens/Dashboard/Customer/Customer.scss";
import Widget from "src/Screens/Dashboard/Components/Widget/Widget";
//import Featured from "src/Screens/Dashboard/Components/Featured/Featured";
import Chart from "src/Screens/Dashboard/Components/Chart/Chart";
import LineChartComponent from "src/Screens/Dashboard/Components/Chart/LineChart";
//import Table from "src/Screens/Dashboard/Components/Table/Table";
import Title from "../Components/Title_text/Title";
import Wrapper from "src/Utlilities/Wrapper";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import useGetPosts from "src/Hooks/useGetPosts";
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CompleteDetails from "./CompleteDetails";

const wrapperHeight = "11vh";
const VendorDashboard = () => {
  // const [proposals, setProposals] = useState([]);
  const navigate = useNavigate();

  const [proposalDiv, setProposalDiv] = useState([]);
  const {data: dashboardData} = useGetPosts({url: "vendor_dashboard",data:{}})
  const { data:proposals, isLoading, getdata } = useGetPosts({
    url: "vendor_proposal_list",
    data: {limit:2},
  })
  useEffect(()=>{
    let divData = [];
    proposals?.data.map(element => {
      //setProposalDiv([...proposalDiv, divData]);
      divData.push({isCollapse: false});
    });
    setProposalDiv(divData);
  }, [proposals]);

  const toggleCollapse = (data, index) => {
    let newData = [...proposalDiv];
    newData[index].isCollapse = !newData[index].isCollapse;
    setProposalDiv(newData);
  };

  const chatScreen = (proposalID) => {
    navigate(`/vendor/chat/${proposalID}`)
  }
  let userDetails = []
  // if(dashboardData?.data?.userdata.veriedId){
  //   userDetails.append['id_not_verified']
  // }
  // if(dashboardData?.data?.userdata.documents.length == 0){
  //   userDetails.append['no_documents']
  // }
  if(dashboardData?.data?.userdata.vendor_address?.length == 0){
    userDetails.push('no_address')
  }
  if(dashboardData?.data?.userdata.payment_methods?.length == 0){
    userDetails.push('no_bank_info')
  }
  if(dashboardData?.data?.userdata.contact_no?.length == 0){
    userDetails.push('no_contact_no')
  }
  if(!dashboardData?.data?.userdata.mobile_verify){
    userDetails.push('mobile_not_verified')
  }
  if(userDetails.length > 0){    
    return(<CompleteDetails userDetails={userDetails}/>) 
  }
  else{
    return (
      <>
        <Wrapper wrapperHeight={wrapperHeight} />
        <div className="customer">
          <Sidebar />
  
          <div className="customerContainer">
            <Title />
            <div className="widgets">
              <Widget type="open_proposal" dashboarData={dashboardData?.data.open_proposals} />
              <Widget type="active_project"  dashboarData={dashboardData?.data.active_projects} />
              <Widget type="complete_project"  dashboarData={dashboardData?.data.completed_project} />
              <Widget type="total_earning"  dashboarData={dashboardData?.data.total_earning} />
            </div>
  
            <div className="charts">
              {/* <Featured /> */}
              <LineChartComponent title="Last 6 Months (Revenue)" aspect={2 / 1} />
              <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
            </div>
  
            <div className="listContainer">
              <div className="listTitle fw-bold mb-4">Active Proposals</div>
  
              {proposals?.data?.map((item, index) => {
                return <div className="border rounded-3 p-3 mb-2">
                <Row>
                  <Col md={6} sm={6} className="pt-2">
                    <MDBBtn color='none' tag='a' rippleColor='dark' onClick={(e) => toggleCollapse(item, index)}>
                      {item.project?.title}
                    </MDBBtn>
                  </Col>
                  <Col md={6} sm={6} className="align-middle">
                    <MDBBadge className='ms-2' pill>2</MDBBadge>
                    {item.service?.approval_status ==="approved" 
                      ?<MDBBadge className='ms-4' pill light>Assigned</MDBBadge>
                      :<MDBBadge className='ms-4' pill light>Not Assigned</MDBBadge>
                    }
                    <span className='ms-4'>{Moment(item?.created_at, "YYYYMMDD").fromNow()}</span>
                    <MDBBtn outline className="float-end ms-3" onClick={()=>chatScreen(item._id)} >
                      <EmailOutlinedIcon /> Chat With Customer
                    </MDBBtn>
                  </Col>
                </Row>
                  <Row>
                    <Col>
                      <MDBCollapse show={proposalDiv[index]?.isCollapse}>
                        <p className="mt-4">
                          <label className="fw-bold">Service Name: </label>
                          <span className="ms-2">{item.service?.title}</span>
                        </p>
                        <p>
                          <label className="fw-bold">Service Description: </label>
                          <span className="ms-2">{item.service?.description}</span>
                        </p>
                      </MDBCollapse>
                    </Col>
                  </Row>
                </div>
              })}
            </div>
            
            {/* <div>
            <button onClick={() => updateToken("satyam_we are good")}>
              Auth Update
            </button>
          </div> */}
          </div>
        </div>
      </>
    );
  }
};

export default VendorDashboard;
