import React, { useEffect } from 'react';
import  HelpWidget  from './HelpWidget';


 const Support = ()=> {
 
  HelpWidget('show', 'launcher');

  return (
    <div>
      <HelpWidget/>
    </div>
  );
}

export default Support;
