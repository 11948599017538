import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
//import { useParams } from "react-router-dom";
import useSavePosts from "src/Hooks/useSavePosts";
import SweetAlert from "react-bootstrap-sweetalert";
import { 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
 } from "mdb-react-ui-kit";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
//import Alert from 'react-bootstrap/Alert';
import { Col, Row } from "react-bootstrap";

const ServiceItems = () => {
    //let { id } = useParams();
    const {NewChats:{proposal}} = useSelector((state) => state);
    const {sendData}=useSavePosts('change_status')
    const {sendData: addLineItem}=useSavePosts('vendor_add_new_line_items')
    const [responseData, setResponseData] = useState({});
    const [confirmStatus,setConfirmStatus] = useState({msg:"",status:false});
    const [lineItemModal, setLineItemModalModal] = useState(false);
    const formRef = useRef(null);
    //const [alertMessage, setAlertMessage] = useState({variant: false, message:'', status: false});
    //const [isSubmit, setIsSubmit] = useState(false);

    let initFormValue = {
        title: '',
        budget: '',
        description: ''
    };
    const [formData, setFormData] = useState(initFormValue);

    useEffect(() => {
        setResponseData(proposal);
    }, [proposal]);

    const handleStatus = async (data) => {
        
        let response = await sendData(data);
        if(data.status==="payment_pending")
        {
            
            setConfirmStatus({msg:"Task Completed",status:true})    
        }
        else
        {
            setConfirmStatus({msg:"Task started",status:true})    
        }
        
        setResponseData(response.data);
    }

    const handleChange = (e) => {
        delete formData?.error;
        setFormData({...formData,[e.target.name]:e.target.value});
      }

    const handleLineItem = (e) => {
        e.preventDefault();
        let error = {}
        //console.log('formData:', formData);
        if(formData.title === '') {
            error.title = 'Title is required!';
        }
        if(formData.description === '') {
            error.description = 'Description is required!';
        }
        if(formData.budget === '') {
            error.budget = 'Cost is required!';
        }
        setFormData({...formData, error});
        if(Object.keys(error).length === 0) {
            //setIsSubmit(true);
            LineItem(formData);
            setLineItemModalModal(false);
        }
    }

    const LineItem = async (data) => {
        //setIsSubmit(false);
        //setLineItemModalModal(false);
        let response = await addLineItem({ 
            proposal_id: responseData._id,
            line_items: [data]
            });
        setResponseData(response.data);
        setConfirmStatus({msg:"Line Item Added",status:true});
    }

    return (
        <>
        <SweetAlert
            success
            title={confirmStatus.msg}
            show={confirmStatus.status}
            onConfirm={()=>{ setConfirmStatus({msg:"",status:false});  }}
        >
            Successfully
        </SweetAlert>
    <div className="proposal-container">
    <ul className="line-item-container">
    {responseData?.todo?.map((mvalue,key)=>{
                    return (<li className="mb-4 border-bottom" key={mvalue._id}>
                        <div className='line-items'>
                            <div className="details">
                                <div className="title-wrapper">
                                    <h4>{mvalue.title} -<span>${mvalue.budget}</span></h4>
                                    <div className="status">
                                        {mvalue.status && mvalue.status==='approved' && (
                                            <>                                            
                                            <span className='alert alert-danger not_started'>Not Started</span>
                                            </>
                                        )}
                                        {mvalue.status && mvalue.status==='rejected' && (
                                            <>
                                            <span className='alert alert-danger'>Rejected</span>
                                            </>
                                        )}
                                        {mvalue.status==="" && (
                                            <>
                                            <span className='alert alert-danger not_started'>Not Started</span>
                                            </>
                                        )}
                                        {mvalue.status && mvalue.status==='started' && (
                                            <>
                                            <span className='alert alert-primary started'>Started</span>
                                            </>
                                        )}
                                        {mvalue.status && mvalue.status==='payment_pending' && (
                                            <>
                                            <span className='alert alert-warning payment_pending'>Approval Pending</span>
                                            </>
                                        )}
                                        {mvalue.status && mvalue.status==='completed' && (
                                            <>
                                            <span className='alert alert-success'>Completed</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <p>{mvalue.description}</p>
                            </div>
                        </div>
                        {
                            mvalue.status === "payment_pending" && (
                                <></>
                                // <div className="select-status">
                                //     <p>Select Status</p>
                                //     <button className="btn btn-primary float-start" onClick={() => {handleStatus({proposal_id: proposal._id, line_item_id: mvalue._id, status: ""})}}>Task Start</button>
                                //     <button className="btn btn-light float-end" disabled>Task Completed</button>
                                //     <div className="clearfix"></div>
                                // </div>
                            )
                        }

                        {
                            mvalue.status === "completed"  && (
                                <></>
                            )
                        }

                        {
                            mvalue.status === "" || mvalue.status === "approved" && (
                                <div className="select-status mb-4">
                                    <p>Select Status</p>
                                    <button className="btn btn-primary float-start" onClick={() => {handleStatus({proposal_id: proposal._id, line_item_id: mvalue._id, status: "started"})}}>Task Start</button>
                                    <button className="btn btn-light float-end" disabled>Task Completed</button>
                                    <div className="clearfix"></div>
                                </div>
                            )
                        }

                        {
                            mvalue.status === "started" && (
                                <div className="select-status mb-4">
                                    <p>Select Status</p>
                                    <button className="btn btn-primary float-start" onClick={() => {handleStatus({proposal_id: proposal._id, line_item_id: mvalue._id, status: "payment_pending"})}}>Task Completed</button>
                                    <button className="btn btn-light float-end" disabled>Task Completed</button>
                                    <div className="clearfix"></div>
                                </div>
                            )
                        }

                            
                    </li>)     
                })}
    </ul>
 
    {/* <div className="actionbuttons">
        <button className="btn btn-success">Accept All</button><br />
        <a href='#'>Request to update  proposal</a>
    </div>
    <div className="amount">
        <span className="amounttitle">Total Amount</span>
        <h4>${proposal?.budget}</h4>
    </div>  */}

    {/* <button className="btn btn-light">Request New Line Item</button> */}
    <MDBBtn color='none' tag='a' rippleColor='dark' className="btn-color" onClick={() => setLineItemModalModal(true)}>
        <AddOutlinedIcon /> Request New Line Item
    </MDBBtn>
    </div>

    <MDBModal show={lineItemModal} setShow={setLineItemModalModal} tabIndex='-1' className="custom-modal" onHide={()=>{ 
                            setFormData(initFormValue);
                          //setAlertMessage({variant: false, message:'', status: false});
                          //setIsSubmit(false);
                        }} staticBackdrop>
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader className="text-center">
              <MDBModalTitle>Add New Line Item</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => setLineItemModalModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            {/* {alertMessage.variant && <Alert key={alertMessage.variant} variant={alertMessage.variant}>
              {alertMessage.message}
            </Alert>} */}
            <h6 className="fw-bold">New Service Line Item</h6>
              <form onSubmit={handleLineItem} className="has-validated" noValidate>
                <Row>
                    <Col md={6} className="">
                        <label className="form-label">Service Name</label>
                        <input type="text" className={`form-control ${formData?.error?.title?'is-invalid':''}`} placeholder="Enter Service Name" onChange={(e) => handleChange(e)} value={formData.title} name="title" />
                        <div className={`${formData?.error?.title?'invalid-feedback':''}`}>
                            {formData?.error?.title??''}
                        </div>
                    </Col>
                    <Col md={6} className="">
                        <label className="form-label">Cost</label>
                        <input type="text" className={`form-control ${formData?.error?.budget?'is-invalid':''}`} placeholder="Enter Cost" onChange={(e) => handleChange(e)} value={formData.budget} name="budget" />
                        <div className={`${formData?.error?.budget?'invalid-feedback':''}`}>
                            {formData?.error?.budget??''}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={12} sm={12} className="">
                        <label className="form-label">Service Description</label>
                        <textarea className={`form-control ${formData?.error?.description?'is-invalid':''}`} name="description" onChange={(e) => handleChange(e)} value={formData.description}  placeholder="Enter Service Description" rows="4" />
                        <div className={`${formData?.error?.description?'invalid-feedback':''}`}>
                            {formData?.error?.description??''}
                        </div>
                    </Col>
                  </Row>
                  <input type="submit" ref={formRef} className="d-none" />
                </form>
            </MDBModalBody>

            <MDBModalFooter>
                <MDBBtn outline onClick={() => setLineItemModalModal(false)} className="btn-color-outline">Cancel</MDBBtn>
                <MDBBtn onClick={(e) => formRef.current.click()} className="btn-color-2">Submit</MDBBtn>
            </MDBModalFooter>
            
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>)
}

export default ServiceItems;