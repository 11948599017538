import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from "./List";
import Edit from "./Edit";

function Address() {
  const [fillActive, setFillActive] = useState("address");
  const {tab, subtab} = useParams();


  useEffect(() => {
    setFillActive(subtab??'address');
    //console.log('Address SUBTABS:', fillActive);
  }, [tab, subtab]);

  const crumbs = [
    {tab: "address", menu: "Address", component: <List />},
    {tab: "add", menu: "Add Address", component: <Edit url="vendor_add_address" msg="Added" />},
    {tab: "edit", menu: "Edit Address", component: <Edit url="vendor_update_address" msg="Updated" />}
  ];

  return (
    <>
    {/* <List /> */}
      {crumbs.map((data) => {
        if(data.tab == fillActive) return data.component
      })}
    </>
  );
}

export default Address;
