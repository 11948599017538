import React from 'react'
import VendorWrapper from '../VendorWrapper'
import { Container, Row, Col } from "react-bootstrap";
import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBContainer,
    MDBNavbar,
    MDBBtn,
    MDBIcon,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem
  } from "mdb-react-ui-kit";
import { Link, useParams } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Button } from "react-bootstrap";


function ServiceRequest() {
  return (
    <VendorWrapper>
    <Col xs={12} md={12}>
    <Container>
    <Row>
      <Col xs={12} md={12}>
        <div className="customer prctCont">
          <div className="customerContainer">
            <div className="breadcrumb-n-title">
              <div className="dashboard-headline">
                <h3>Service Requests</h3>
              </div>
              <div className="breadcrum-ui-kit">
                <MDBNavbar expand="lg" light bgColor="dark">
                  <MDBContainer fluid>
                    <MDBBreadcrumb>
                      <MDBBreadcrumbItem className="breadcrum-item">
                        <Link to="/">Home</Link>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem className="breadcrum-item">
                        <Link to="/vendor/dashboard">Dashboard</Link>
                      </MDBBreadcrumbItem>
                      <MDBBreadcrumbItem active className="breadcrum-item">
                        <Link to="#">Service Requests</Link>
                      </MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                  </MDBContainer>
                </MDBNavbar>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
  <Container>
  <Row>
  <Col md={12} xs={12}>
      <Container className="project-listing-container">
      <Row>
          <Col md={12} xs={12}>
          <div className="project-lisitng">
              {/* <BsFillBookmarkFill /> */}
              <div className="project-listing-title">
              <BusinessCenterIcon />
              {/* {jobsOpen ? <p>Active Services</p> : <p>Inactive Services</p>} */}
              </div>
              <div className="project-listing-action-buttons">
              {/* <Button>
                  <Link to="/vendor/service/add">Add Service</Link>{" "}
              </Button> */}
              <Button href="/vendor/service/add">Accept Request</Button>
              {/* <MDBBtn floating tag='a' color="link"><MDBIcon fas icon="ellipsis-v" /></MDBBtn> */}

              <MDBDropdown className="ms-2">
                <MDBDropdownToggle tag='a' className="ellipsis-button">
                  <MDBIcon fas icon="ellipsis-v" size='lg' />
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link href="/vendor/services/active">Active</MDBDropdownItem>
                  <MDBDropdownItem link href="/vendor/services/inactive">In active</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              </div>
          </div>
          </Col>
      </Row>

      {/* {
        services?.data && services.data.length ? (
          services.data.map((service) => (            
              <Row>
                  <ServiceCard service={service} deleteService={(service_id) =>
                    setDeleteModel({
                      status: true,
                      service_id: service_id,
                    }) } />
              </Row>
            ))) : (
              <p>{isLoading ? "Loading..." : "data not found"}</p>
            )
      } */}
      </Container>
  </Col>
  </Row>

  </Container>
</Col>
</VendorWrapper>
  )
}

export default ServiceRequest