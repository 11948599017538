import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatBody from 'src/Screens/Dashboard/Vendor/Chat/ChatScreen/ChatBody/Index';
import ProposalView from './ProposalView';
import { Accordion } from "react-bootstrap";
//import ProposalToDo from "./ProposalToDo";
import AboutVendor from "./AboutVendor";
import ServiceItems from "./ServiceItems";
import LogsView from "./LogsView";
import RaiseDispute from "./RaiseDispute";
import GeegrCreditVendor from "./GeegrCreditVendor";
//import ActiveUsers from 'src/Screens/Dashboard/Vendor/Chat/ActiveUsers/Index';
//import { BsFillBookmarkFill } from "react-icons/bs";
// import useFetchPost from "../../../Hooks/useFetchPost";
import { useSelector } from "react-redux";

const Index = () => {
  //const getState = useSelector((state) => state);
  const {NewChats:{proposal}} = useSelector((state) => state);
  const [style, setStyle] = useState([
    // textColor: {
    //   color: '#1E1E1E'
    // }
  ]);

  const initialAccordionItems = [
    {eventKey: 1, heading: "Review Proposal", component: <ProposalView />, display: true},
    {eventKey: 2, heading: "ToDo", component: <ServiceItems />, display: false},
    {eventKey: 3, heading: "Logs", component: <LogsView />, display: true},
    {eventKey: 4, heading: "Raise Dispute", component: <RaiseDispute />, display: false},
    {eventKey: 5, heading: "Recent Transactions", component: <GeegrCreditVendor />, display: false}
  ];

  const [accordionItems, setAccordionItems] = useState(initialAccordionItems);

  useEffect(() => {
    //console.log('proposal.todo.length', proposal?.todo??false);
    let accordions = [...accordionItems];
    accordions[1].display = (proposal?.todo.length>0)?true:false;
    accordions[3].display = (proposal?.todo.length>0)?true:false;
    accordions[4].display = (proposal?.todo.length>0)?true:false;
    setAccordionItems(accordions);
  }, [proposal]);

  useEffect(() => {
    let init = [];
    accordionItems.map(() => {
      init.push({textColor: {color: '#1E1E1E'}});
      return null;
    });
    setStyle(init);
  }, []);

  const handleAccordionClick = (key) => {
    //let data = style;
    style[key-1].textColor = {color: '#6A2FF9'};
    //setStyle(style);
    console.log(style[key-1]);
  }

  useEffect(() => {
    console.log(style);
  }, [style]);

  return (
    <>
    <Col xs={12} md={12}>
      {/* <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="customer">
              <div className="customerContainer">
                <div className="breadcrumb-n-title">
                  <div className="dashboard-headline">
                    <h3>Manage Projects</h3>
                  </div>
                  <div className="breadcrum-ui-kit">
                    <MDBNavbar expand="lg" light bgColor="dark">
                      <MDBContainer fluid>
                        <MDBBreadcrumb>
                          <MDBBreadcrumbItem>
                            <a href="#">Home</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem>
                            <a href="#">Library</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem active>
                            <a href="#">Data</a>
                          </MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                      </MDBContainer>
                    </MDBNavbar>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container>
        <Row>
          <Col md={12} xs={12}>
            <Container className="customer-chat-container">
              <Row>
                <Col md={8} xs={8}>
                    <div className="chatmainbody">
                      <ChatBody />
                    </div>
                </Col>
                <Col md={4} xs={4}>
                 <div className="chatrightbar">

                 <AboutVendor />
                 
                 {/* <div class="card mb-3 shadow bg-body rounded-3"> */}
                  {/* <div class="card-body p-0"> */}
                  {/* <h5 class="card-title border-bottom pt-3 pb-3 ps-3 fs-6 fw-bold">Service Item To-Do List</h5> */}
                  <Accordion defaultActiveKey="0" className="">
                      {/* <Accordion.Item eventKey="0">
                        <Accordion.Header><h3>About Vendor</h3></Accordion.Header>
                        <Accordion.Body>
                          <AboutVendor />
                        </Accordion.Body>
                      </Accordion.Item> */}
                      {/* <Accordion.Item eventKey="1">
                        <Accordion.Header><h3>Review Proposal</h3></Accordion.Header>
                        <Accordion.Body>
                          <ProposalView />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header><h3>Service Items</h3></Accordion.Header>
                        <Accordion.Body>
                          <ServiceItems />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header><h3>Logs</h3></Accordion.Header>
                        <Accordion.Body>
                          <LogsView />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header><h3>Raise Dispute</h3></Accordion.Header>
                        <Accordion.Body>
                          <ProposalToDo />
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header><h3>Recent Transactions</h3></Accordion.Header>
                        <Accordion.Body>
                          <GeegrCreditVendor />
                        </Accordion.Body>
                      </Accordion.Item> */}

                      {
                        accordionItems.map((item) => {
                          if(item.display) {
                          return <Accordion.Item eventKey={item.eventKey}>
                            <Accordion.Header onClick={() => {handleAccordionClick(item.eventKey);}}><h3 style={style[item.eventKey]?.textColor}>{item.heading}</h3></Accordion.Header>
                            <Accordion.Body>
                              {item.component}
                            </Accordion.Body>
                          </Accordion.Item>
                          } else {
                            return null;
                          }
                        })
                      }
                      
                  </Accordion>
                  {/* </div> */}
                 {/* </div> */}
                    
                  </div>
                </Col>
              </Row>     
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  </>
  );
};

export default Index;
