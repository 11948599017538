import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Moment from 'moment';
import {
    MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBTable, MDBTableHead, MDBTableBody
} from "mdb-react-ui-kit";
import FormSelect from "react-bootstrap/FormSelect";
import CustomerWrapper from "src/Screens/Dashboard/Customer/CustomerWrapper";
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import useGetPosts from "src/Hooks/useGetPosts";

function Transaction() {
  
  const {data: transactionData} = useGetPosts({url: "customer_transaction",data:{}})

  const crumbs = [
    //{tab: "profile", menu: "Profile", component: <ProfileUpdate />, icon: <ArticleOutlinedIcon />}
  ];

  const selectData = useMemo(() => [
    { text: 'Week', value: 1, defaultSelected: true },
    { text: 'Month', value: 2 },
  ], []);

  return (
    <CustomerWrapper>

        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="customer prctCont">
                <div className="customerContainer">
                  <div className="breadcrumb-n-title">
                    <div className="dashboard-headline">
                      <h3>Transaction Histroy</h3>
                      {/* <p>
                        Setting <ArrowForwardIosIcon /> 
                      </p> */}
                    </div>
                    <div className="breadcrum-ui-kit">
                      <MDBNavbar expand="lg" light bgColor="dark">
                        <MDBContainer fluid>
                          <MDBBreadcrumb>
                            <MDBBreadcrumbItem>
                              <Link to="/">Home</Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>
                              <Link to="/customer_dashboard">Dashboard</Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem active>
                              <Link to="#">Transaction</Link>
                            </MDBBreadcrumbItem>
                          </MDBBreadcrumb>
                        </MDBContainer>
                      </MDBNavbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
            <Row>
            <Col md={12} xs={12}>
            <Container className="project-listing-container">
                <Row>
                <Col md={12} xs={12}>
                    <div className="card-lisitng">
                    <div className="project-listing-title">
                        <SwapHorizOutlinedIcon />
                        <p>Geegr Transaction History</p>
                    </div>
                    <div className="project-listing-title">
                        <LocalPrintshopOutlinedIcon />
                        <FormSelect size="sm" className="ms-4">
                        <option><i><CalendarTodayOutlinedIcon /></i> select</option>
                            {selectData.map((data) => {
                                return (<option value={data.value} selected={data?.defaultSelected?'selected':''}>{data.text}</option>);
                            })}
                            
                        </FormSelect>
                    </div>
                    </div>
                </Col>
                </Row>

                <Row>
                <Col>
                    <div className="addAddresContainer">
                    <MDBTable hover>
                        <MDBTableHead>
                            <tr>
                            <th scope='col' className="fw-bold">Transaction Details</th>
                            <th scope='col' className="fw-bold">Date</th>
                            <th scope='col' className="fw-bold">Amount</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {transactionData?.data && 
                              transactionData.data.map((item)=> (
                                <tr>
                                  <th scope='row'>{item?.transaction_in && item.transaction_in +" - "} {item.description}</th>
                                    <td>{item.created_at ? Moment(item.created_at).format('DD MMM YYYY'):'-'}</td>
                                  <td><SouthEastOutlinedIcon color={item?.transacton_type && item.transacton_type==="credit"?"success":"error"} />{item.amount? "  $"+item.amount:" - "}</td>
                                </tr>
                              ))
                            }
                            {/* <tr>
                              <th scope='row'>Tester Project - Withdrawn</th>
                                <td>Today</td>
                                <td><NorthEastOutlinedIcon color="success" /> $120</td>
                            </tr>
                            <tr>
                            <th scope='row'>Plumber Project - Extra Payment - Extra Tap Repair - Deposite - Geegr</th>
                            <td>24 Jan 2023</td>
                            <td><SouthEastOutlinedIcon color="error" /> $20</td>
                            </tr>

                            <tr>
                            <th scope='row'>Plumber Project - Deposite - Geegr</th>
                            <td>20 Jan 2023</td>
                            <td><SouthEastOutlinedIcon color="error" /> $115</td>
                            </tr> */}
                        </MDBTableBody>
                        </MDBTable>
                    </div>
                </Col>
                </Row>
                
            </Container>
            </Col>
        </Row>
        </Container>
      
    </CustomerWrapper>
  );
}

export default Transaction;