import "src/Screens/Dashboard/Customer/Components/Sidebar/Sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import { Link } from "react-router-dom";



// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";

const Sidebar = () => {
  //   const { dispatch } = useContext(DarkModeContext);
  // const location = useLocation();

  return (
    <div className="sidebar">
      <div className="center"> 
        <ul>
          <Link>
            <li >
              <span>Dashboard</span>
            </li>
          </Link>
          <Link>
            <li>
              <span>Users</span>
            </li>
          </Link>
          <Link to="/admin/employee">
            <li className="activemenu">
              <span>User Management</span>
            </li>
          </Link>
          <Link>
            <li>
              <span>Posted Projects</span>
            </li>
          </Link>
          <Link>
            <li>
              <span>Category Screen</span>
            </li>
          </Link>
          <li>
            <span>Ticket Management</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
