import React from "react";
import VendorWrapper from "../VendorWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
	MDBBreadcrumb,
	MDBBreadcrumbItem,
	MDBContainer,
	MDBNavbar,
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalHeader,
	MDBModalTitle,
	MDBModalDialog,
	MDBModalFooter,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { Input } from "@mui/material";

function CompleteDetails({ userDetails }) {
	const navigate = useNavigate();
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [otp, setOtp] = useState("");

	console.log(userDetails, "userDetails");
	return (
		<div>
			<VendorWrapper>
				<Col xs={12} md={12}>
					<Container>
						<Row>
							<Col xs={12} md={12}>
								<div className="customer prctCont">
									<div className="customerContainer">
										<div className="breadcrumb-n-title">
											<div className="dashboard-headline">
												<h3>Howdy, David</h3>
											</div>
											<div className="breadcrum-ui-kit">
												<MDBNavbar
													expand="lg"
													light
													bgColor="dark"
												>
													<MDBContainer fluid>
														<MDBBreadcrumb>
															<MDBBreadcrumbItem className="breadcrum-item">
																<Link to="/">
																	Home
																</Link>
															</MDBBreadcrumbItem>
															<MDBBreadcrumbItem className="breadcrum-item">
																<Link to="/vendor/dashboard">
																	Dashboard
																</Link>
															</MDBBreadcrumbItem>
														</MDBBreadcrumb>
													</MDBContainer>
												</MDBNavbar>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col xs={12} md={12}>
								<div className="dashboard-headline">
									<h6 className="ms-4 text-muted">
										Please complete the mandatory details
										first then only you will be able to
										Submit proposal on a project
									</h6>
								</div>
							</Col>
						</Row>
					</Container>

					<Container>
						<MDBModal
							show={showOtpModal}
							setShow={setShowOtpModal}
							tabIndex="-1"
						>
							<MDBModalDialog>
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>
											Verify Mobile
										</MDBModalTitle>
										<MDBBtn
											className="btn-close"
											color="none"
											onClick={() =>
												setShowOtpModal(false)
											}
										></MDBBtn>
									</MDBModalHeader>
									<MDBModalBody>
										<Row>
											<Col className="otp_submit">
												<Input
													value={otp}
													type="numeric"
												/>
											</Col>
											<Col>Resend OTP</Col>
										</Row>
									</MDBModalBody>

									<MDBModalFooter>
										<Row>
											<Col>
                                                <div className="dashboard-button">
                                                    <MDBBtn  rippleColor="dark">
                                                        Send OTP
                                                    </MDBBtn>
                                                </div>

											</Col>
											<Col>
                                            <div className="dashboard-button">
                                                    <MDBBtn  rippleColor="dark">
                                                        Submit OTP
                                                    </MDBBtn>
                                                </div>
											</Col>
										</Row>
									</MDBModalFooter>
								</MDBModalContent>
							</MDBModalDialog>
						</MDBModal>
						<Row>
							<Col md={12} xs={12}>
								<Container>
									{userDetails.includes(
										"id_not_verified"
									) && (
										<Row>
											<Col md={12} xs={12}>
												<div className="personal-details-list">
													<div className="project-listing-title">
														<span className="text-muted">
															You have to take the
															image for self
															verifcation, just
															click the button to
															verify yourself
														</span>
													</div>
													<div className="dashboard-button">
														<MDBBtn
															onClick={() =>
																navigate(
																	"/vendor/profile/profile"
																)
															}
															rippleColor="dark"
														>
															Verify your identity
														</MDBBtn>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{userDetails.includes("no_documents") && (
										<Row>
											<Col md={12} xs={12}>
												<div className="personal-details-list">
													<div className="project-listing-title">
														<span className="text-muted">
															You have to upload
															your driving
															license(Valid
															Identity Card) for
															verification.
														</span>
													</div>
													<div className="dashboard-button">
														<MDBBtn rippleColor="dark">
															Upload Document
														</MDBBtn>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{userDetails.includes("no_address") && (
										<Row>
											<Col md={12} xs={12}>
												<div className="personal-details-list">
													<div className="project-listing-title">
														<span className="text-muted">
															You have to add an
															address for self
															verifcation. Just
															click button to
															verify add an
															address.
														</span>
													</div>
													<div className="dashboard-button">
														<MDBBtn
															onClick={() =>
																navigate(
																	"/vendor/profile/address"
																)
															}
															rippleColor="dark"
														>
															Add An address
														</MDBBtn>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{userDetails.includes("no_bank_info") && (
										<Row>
											<Col md={12} xs={12}>
												<div className="personal-details-list">
													<div className="project-listing-title">
														<span className="text-muted">
															You have to add bank
															information to
															receive funds. Just
															click button to add
															an account.
														</span>
													</div>
													<div className="dashboard-button">
														<MDBBtn
															rippleColor="dark"
															onClick={() =>
																navigate(
																	"/vendor/profile/payment"
																)
															}
														>
															Add Bank Information
														</MDBBtn>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{userDetails.includes("no_contact_no") ||
										(userDetails.includes(
											"mobile_not_verified"
										) && (
											<Row>
												<Col md={12} xs={12}>
													<div className="personal-details-list">
														<div className="project-listing-title">
															<span className="text-muted">
																You have to
																verify mobile
																number for self
																verifcation.
																Just click
																button to
																verify.
															</span>
														</div>
														<div className="dashboard-button ">
															{userDetails.includes(
																"no_contact_no"
															) ? (
																<MDBBtn
																	onClick={() =>
																		navigate(
																			"/vendor/profile/profile"
																		)
																	}
																	rippleColor="dark"
																>
																	Mobile
																	Verification
																</MDBBtn>
															) : (
																<MDBBtn
																	rippleColor="dark"
																	onClick={() =>
																		setShowOtpModal(
																			true
																		)
																	}
																>
																	Mobile
																	Verification
																</MDBBtn>
															)}
														</div>
													</div>
												</Col>
											</Row>
										))}
								</Container>
							</Col>
						</Row>
					</Container>
				</Col>
			</VendorWrapper>
		</div>
	);
}

export default CompleteDetails;
