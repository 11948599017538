import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import AnalyticCards from './AnalyticCards';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ComplainSvg from "../../../../assets/images/complaint_svgrepo.png"
import SpeedSvg from "../../../../assets/images/speed_svgrepo.png"
import RatingSurvey from "../../../../assets/images/rate-rating-survey-18_svgrepo.png"
import RatingSurveyChat from "../../../../assets/images/rate-rating-survey-30_svgrepo.png"
import Job from "../../../../assets/images/job_svgrepo.svg"
import Note from "../../../../assets/images/rate-rating-survey-23_svgrepo.png"


// const data = [
//   {title:"Job Done ",noOf:"32",logo:{Job}},
//   {title:"Job Success Rate",noOf:"96.3%",logo:{Note}},
//   {title:"Total Proposal Shared",noOf:"48",logo:{RatingSurveyChat}},
//   {title:"Satisfaction Rate",noOf:"8.9",logo:{RatingSurvey}},
//   {title:"Avg. Distance b/w job",noOf:"4.8 Miles",logo:{SpeedSvg}},
//   {title:"Number Of Dispute",noOf:"4",logo:{ComplainSvg }},
// ]
const newData = {title:"Job Done ",noOf:"32",logo:{Job}}
function Analytics() {
  return (
    <div className='analyticContainer'>
      <div className='analyticTop'>
      <span>Analytics Report</span>
      <div>
      <Dropdown>
      <Dropdown.Toggle  id="dropdown-basic">
      Month
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown></div>
      
      </div>
      <div className='analyticBottom'>
      <AnalyticCards title="Job Done"  noOf="32" logo={Job}  />
      <AnalyticCards title="Job Success Rate"  noOf="96.3%" logo={Note}  />
      <AnalyticCards title="Total Proposal Shared"  noOf="48" logo={RatingSurveyChat}  />
      <AnalyticCards title="Satisfaction Rate"  noOf="8.9" logo={RatingSurvey}  />
      <AnalyticCards title="Avg. Distance b/w job"  noOf="4.8" logo={SpeedSvg}  />
      <AnalyticCards title="Number Of Dispute"  noOf="4" logo={ComplainSvg}  />
     
      </div>
    </div>
  )
}

export default Analytics

