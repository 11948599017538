import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

function DocumentUpload() {
  return (
    <div className="documentUploadContainer">
      <h1>Documents Uploaded</h1>
      <div className="uploadBtn">
        <span>
          identity-card-document.pdf
          <VisibilityIcon />
        </span>
      </div>
    </div>
  );
}

export default DocumentUpload;
