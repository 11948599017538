import Sidebar from "src/Screens/Dashboard/Customer/Components/Sidebar/Sidebar";
import "src/Screens/Dashboard/Customer/Customer.scss";
import Widget from "src/Screens/Dashboard/Components/Widget/Widget";
import Featured from "src/Screens/Dashboard/Components/Featured/Featured";
import Chart from "src/Screens/Dashboard/Components/Chart/Chart";
import Table from "src/Screens/Dashboard/Components/Table/Table";
import Title from "src/Screens/Dashboard/Components/Title_text/Title";
import Wrapper from "src/Utlilities/Wrapper";
import Accordion from "./Components/Accordion/Accordion";
import AccordionCopy from "./Components/Accordion/AccordionCopy";
import useGetPosts from "src/Hooks/useGetPosts";

const wrapperHeight = "11vh";
const Customer = () => {
  const {data: dashboardData} = useGetPosts({url: "customer_dashboard",data:{}})
  return (
    <>
      <Wrapper wrapperHeight={wrapperHeight} />
      <div className="customer">
        <Sidebar />

        <div className="customerContainer">
          <Title />
          <div className="widgets">
            <Widget type="active_project"  dashboarData={dashboardData?.data.active_projects} />
            <Widget type="complete_project" dashboarData={dashboardData?.data.completed_project} />
            <Widget type="geegr_credit" dashboarData={dashboardData?.data.geegr_credit} />
            <Widget type="customer_review" dashboarData={dashboardData?.data.customer_reviews} />
          </div>

          <div className="listContainer">
            <div className="listTitle fw-bold">Active Projects</div>
            <Accordion />
          </div>

          <div className="listContainer">
            <div className="listTitle fw-bold">Pending Payments</div>
            <AccordionCopy type="payment" />
          </div>
          
          {/* <div>
          <button onClick={() => updateToken("satyam_we are good")}>
            Auth Update
          </button>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Customer;
