import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";

function BtnAddEmployee() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // You can replace '/target-page' with the URL of the page you want to navigate to
    navigate('/admin/add_employee');
  };

  return (
    <button onClick={handleButtonClick}>
      <AddIcon />
      Add Employee
    </button>
  );
}

export default BtnAddEmployee;
