import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
//import useSavePosts from "src/Hooks/useSavePosts";
import useSendFormData from "src/Hooks/useSendFormData";
import ProfileForm from "../../Customer/Settings/ProfileForm";
import SweetAlert from "react-bootstrap-sweetalert";
import useGetPosts from "src/Hooks/useGetPosts";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar
} from "mdb-react-ui-kit";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getuserinfo } from "../../../../redux/actions/UserActions";

function VendorProfile() {
  const [formStatus, setFormStatus] = useState({msg:"",status:false,id:0});
  const [formdata, setFormData] = useState({});  
  const {sendForm:saveProfile}= useSendFormData("profile_update");
  const {data:profile_detail}= useGetPosts({url: "vendor_profile_detail"});
  
  const dispatch = useDispatch();
  const { userSignin: userInfo } = useSelector((state) => state);

  let validations={
  }
  const  submitProfile=async (data) => {


    console.log(data, 'ddddddddddd');

    var fd = new FormData();

    for (var item in data) {
      if (item !== 'images') {
        fd.append(item, data[item]);
        console.log(item + " => "+data[item]);
      }
    }
  
    // Ensure that data.images is an array
    if (Array.isArray(data.images)) {
      data.images.forEach((image) => {
        // Append each image individually
        fd.append('images', image);
      });
    }
    console.log(fd, 'LLLLLLLLLLLLL');

    const result = await saveProfile(fd); // Assuming saveProfile expects FormData
    
    result?.status === true && setFormStatus({ msg: 'Updated', status: true, id: result.data._id });
    console.log("++++++++++++++");
    console.log(userInfo.userInfo.data.user, "at header (REDUX)");
    console.log("++++++++++++++");
    dispatch(getuserinfo(result.data, ""));
    setFormData(data);


  }
  useEffect(() =>{
    console.log("profile_detail", profile_detail);
    if(profile_detail?.data){
      setFormData(profile_detail.data)
    }

  },[profile_detail])
  return (
    <>
      <SweetAlert
      success
      title={formStatus.msg}
      show={formStatus.status}
      onConfirm={()=>{ setFormStatus({msg:"",status:false});  }}
    >
      Successfully
    </SweetAlert>
      

        <Container>
        <ProfileForm defaultvalues={formdata}  validations={validations} submitProfile={submitProfile} />
        </Container>
    </>
  );
}

export default VendorProfile;
