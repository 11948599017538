const initialState = {
  userInfo: {},
  isLoggedIn: false,
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_SIGNIN_REQUEST":
      return { ...state, userInfo: action.payload, isLoggedIn: true };
    case "USER_SIGNUP_REQUEST":
      return { ...state, userInfo: action.payload, isLoggedIn: true };
    case "USER_SIGNOUT":
      return {};
    case "USER_SIGNIN_FAIL":
       return { ...state, userInfo: action.payload };
    case "USER_UPDATE_REQUEST":
            
      return { ...state, userInfo: { ...state.userInfo, data: { ...state.userInfo.data, user: action.payload }}, isLoggedIn: true };
    default:
      return state;
  }
};
