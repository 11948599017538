import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import useSendFormData from "src/Hooks/useSendFormData";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
} from "mdb-react-ui-kit";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";

import { useSelector } from "react-redux";

function ChangePassword() {
  const [formdata, setFormData] = useState({});
  const [formStatus, setFormStatus] = useState({msg:"",status:false,id:0});
  const [errors, setErrors] = useState({});

  const {sendForm:savePassword}= useSendFormData("change_password");

  const { userSignin: userInfo } = useSelector((state) => state);

  let validations={
  }

  const handleChange= (e) =>{
    setFormData({...formdata,[e.target.name]:e.target.value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tempErrors = {};

    // Validation rules
    if (!formdata.old_password || formdata.old_password.trim() === '') {
      tempErrors.old_password = "Old password is required";
    }
    if (!formdata.new_password || formdata.new_password.trim() === '') {
      tempErrors.new_password = "New password is required";
    }
    if (!formdata.confirm_password || formdata.confirm_password.trim() === '') {
      tempErrors.confirm_password = "Confirm password is required";
    }
    if (formdata.new_password !== formdata.confirm_password) {
      tempErrors.confirm_password = "Passwords do not match";
    }
    
    

    if (Object.keys(tempErrors).length !== 0) {
      setErrors(tempErrors);
    } else {
      // CALL API FOR UDPDATED PASSWORD START  

      var fd = new FormData();

      for (var item in formdata) {
        if (item !== 'images') {
          fd.append(item, formdata[item]);
          console.log(item + " => "+formdata[item]);
        }
      }

      console.log(fd,"Before Send to API");                  
      const result = await savePassword(fd); // Assuming saveProfile expects FormData  
      result?.status==true && setFormStatus({msg: "Password updated successfully", status: true});      
      result?.status==false && setFormStatus({msg: result?.error, status: true});    

      console.log(result);
      // CALL API FOR UDPDATED PASSWORD END
      setErrors({});      
    }
  }


  



  return (
    <>       
    <SweetAlert
      success
      title={formStatus.msg}
      show={formStatus.status}
      onConfirm={()=>{ setFormStatus({msg:"",status:false});  }}
    >      
    </SweetAlert>
      <Col xs={12} md={12}>
        {/* <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="customer prctCont">
                <div className="customerContainer">
                  <div className="breadcrumb-n-title">
                    <div className="dashboard-headline">
                      <h3>Settings</h3>
                      <p>
                        Setting <ArrowForwardIosIcon /> Change Password/Location
                      </p>
                    </div>
                    <div className="breadcrum-ui-kit">
                      <MDBNavbar expand="lg" light bgColor="dark">
                        <MDBContainer fluid>
                          <MDBBreadcrumb>
                            <MDBBreadcrumbItem>
                              <Link to="/">Home</Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>
                              <a href="/customer_dashboard">Dashboard</a>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>
                              <a href="/customer_dashboard">Settings</a>
                            </MDBBreadcrumbItem>
                          </MDBBreadcrumb>
                        </MDBContainer>
                      </MDBNavbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container> */}
        <Container>
          <Row>
            <Col md={12} xs={12}>
              <Container className="project-listing-container">
                <Row>
                  <Col md={12} xs={12}>
                    <div className="card-lisitng">
                      <div className="project-listing-title">
                        <LockOutlinedIcon />
                        <p>Change Password</p>
                      </div>
                    </div>
                  </Col>
                </Row>


                {userInfo?.userInfo?.data?.user?.is_social_login ? (
                <>
                <Row>
                    <Col md={12} sm={12}>
                      <Container fluid>
                        <Row>
                          <Col md={9}>
                              <Row>
                                  <Col md={9}></Col>
                                  <p>&nbsp;</p>
                                  Since you're using social login, there's no need for a password change screen.
                                  <p>&nbsp;</p>
                              </Row>
                          </Col>
                        </Row>           
                      </Container>
                     </Col>
                  </Row>      
                </>
              ) : (
                <>
                 <form>
                  <Row>
                    <Col md={12} sm={12}>
                      <Container fluid>
                        <Row>
                          <Col md={9}>
                              <Row>
                                  <Col md={9}>
                                    <div className={`city-dropdown streetfeild ${errors?.old_password ? 'is-invalid' : ''}`}>
                                      <label className="fw-bold">Current Password</label>
                                      <input required type="password" className="form-control" placeholder="Enter Current Password" value={formdata?.old_password || ''} name="old_password" onChange={(e) => handleChange(e)} />
                                      {errors.old_password && <p className="text-danger">{errors.old_password}</p>}
                                    </div>
                                  </Col>
                                  <Col md={9}>
                                    <div className={`city-dropdown streetfeild ${errors?.new_password ? 'is-invalid' : ''}`}>
                                      <label className="fw-bold">New Password</label>
                                      <input required type="password" className="form-control" placeholder="Enter New Password" value={formdata?.new_password || ''} name="new_password" onChange={(e) => handleChange(e)} />
                                      {errors.new_password && <p className="text-danger">{errors.new_password}</p>}
                                    </div>
                                  </Col>
                                  <Col md={9}>
                                    <div className={`city-dropdown streetfeild ${errors?.confirm_password ? 'is-invalid' : ''}`}>
                                      <label className="fw-bold">Confirm Password</label>
                                      <input required type="password" className="form-control" placeholder="Re-enter New Password" value={formdata?.confirm_password || ''} name="confirm_password" onChange={(e) => handleChange(e)} />
                                      {errors.confirm_password && <p className="text-danger">{errors.confirm_password}</p>}
                                    </div>
                                  </Col>
                                  
                              </Row>
                          </Col>
                        </Row>
                        
                      </Container>
                    </Col>
                  </Row>
                </form>
                </>
              )}
                
                

              </Container>
            </Col>
          </Row>

          {userInfo?.userInfo?.data?.user?.is_social_login ? (
                <>
                    
                </>
              ) : (
                <>
                <button className="saveChanges" onClick={(e) => {  e.preventDefault(); handleSubmit(e) }}>Update Password</button>
                </>
              )
          }   




        </Container>
      </Col>
    </>
  );
}

export default ChangePassword;
