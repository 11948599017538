import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
    MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomerWrapper from "src/Screens/Dashboard/Customer/CustomerWrapper";
import ProfileUpdate from "./ProfileUpdate";
//import Address from "./Address";
import Address from "./Address/Address";
import Payment from "./Payment";
import ChangePassword from "./ChangePassword";

function SettingTabs() {
  const [fillActive, setFillActive] = useState("profile");
  const {tab} = useParams();

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  useEffect(() => {
    setFillActive(tab??'profile');
    console.log('TABS:', fillActive);
  }, [tab]);

  const crumbs = [
    {tab: "profile", menu: "Profile", component: <ProfileUpdate />, icon: <ArticleOutlinedIcon />},
    {tab: "customer_address", menu: "Address", component: <Address />, icon: <LocationOnOutlinedIcon />},
    {tab: "customer_payment", menu: "Payment", component: <Payment />, icon: <BusinessCenterOutlinedIcon />},
    {tab: "customer_change_password", menu: "Change Password", component: <ChangePassword />, icon: <LockOutlinedIcon />}
  ];

  return (
    <CustomerWrapper>

        {/* <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="customer prctCont">
                <div className="customerContainer">
                  <div className="breadcrumb-n-title">
                    <div className="dashboard-headline">
                      <h3>Settings</h3>
                      <p>
                        Setting <ArrowForwardIosIcon /> {crumbs.map((data) => {
                            if(data.tab === fillActive) return data.menu
                        })}
                      </p>
                    </div>
                    <div className="breadcrum-ui-kit">
                      <MDBNavbar expand="lg" light bgColor="dark">
                        <MDBContainer fluid>
                          <MDBBreadcrumb>
                            <MDBBreadcrumbItem>
                              <Link to="/">Home</Link>
                            </MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>
                              <a href="/customer_dashboard">Dashboard</a>
                            </MDBBreadcrumbItem>
                          </MDBBreadcrumb>
                        </MDBContainer>
                      </MDBNavbar>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container> */}

      <MDBTabs className="mb-3">
        {crumbs.map((data, i) => {
            return (
                <MDBTabsItem key={i}>
                    <Link to={`/customer_settings/${data.tab}`} style={{ textDecoration: "none"}}>
                        <MDBTabsLink active={fillActive === data.tab}>{data.icon} {data.menu}</MDBTabsLink>
                    </Link>
                </MDBTabsItem>
            )
        })}
      </MDBTabs>

      <MDBTabsContent>
        {crumbs.map((data, i) => {
            return (
                <MDBTabsPane key={i} show={fillActive === data.tab}>{data.component}</MDBTabsPane>
            )
        })}
      </MDBTabsContent>
    </CustomerWrapper>
  );
}

export default SettingTabs;
