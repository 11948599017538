import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../Components/Sidebar/Sidebar";
//import AddMoreUser from "../Dashboard/AdUser";
import TabsOptions from "./Tabs/TabsOptions";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./../Admin.scss"

import { Link, useParams } from "react-router-dom";

import BtnAddEmployee from "./btn_add_employee";

import useGetEmployee from "src/Hooks/useGetEmployee";
import useDeletePost from "src/Hooks/useDeletePost";
import SweetAlert from "react-bootstrap-sweetalert";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//import DashBoard from Admin;
function Index() {
  const [selectAll, setSelectAll] = useState(false);
  const [addItem, setItem] = useState();
  const [openDetails, setOpenDetails] = useState();
  const [data, setData] = useState();

  const { type } = useParams();

  const { data:employees, isLoading, getdata } = useGetEmployee({
    url: "admin_employee_list",
    data: { status: type ? type : "active" },
  });

  const employeeRoles = [{ role: 'Manager', id: 1 },{ role: 'Operator', id: 2 }];

 

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedData = data.map((item) => ({ ...item, selected: !selectAll }));
    setData(updatedData);
  };
  const handleCheckboxChange = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setSelectAll(updatedData.every((item) => item.selected));
  };


  const { apply, data: deleted_status } = useDeletePost("admin_delete_employee");

  const [deleteModel, setDeleteModel] = useState({
    status: false,
    project_id: 0,
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    status: false,
  });

 
 const deletepro = async () => {
  await apply({ employee_id: deleteModel.employee_id }); // Change project_id to employee_id
  await getdata({ status: type });
  setDeleteConfirmation({ status: true });
};

  const deleteProject = async (eID) => {   
    console.log(" Employee Id " + eID);
    setDeleteModel({ status: true, employee_id: eID });
    
  };


  return (
    <>
     <SweetAlert
        success
        title="Deleted"
        show={deleteConfirmation.status}
        onConfirm={() => {
          setDeleteConfirmation({ status: false });
        }}
      >
        Successfully
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        title="Are you Sure"
        show={deleteModel.status}
        onConfirm={() => {
          deletepro();
        }}
        onCancel={() => {
          setDeleteModel({ status: false, project_id: 0 });
        }}
      ></SweetAlert>
      <div>      
        <div className="dashBoardBodyContainer">
          { <Sidebar /> }
          {openDetails ? (
            <TabsOptions />
              ) : (
            <div className="tableContainer">
              <div className="searchBoxContainer">
                <div className="riteSideContainer">
                  <input type="search" placeholder="Search" />
                  <SearchIcon />
                </div>

                  <div className="categoryDropContainer">
                  <Dropdown className="drop">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Select Roles
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Admin</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Vendor
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>


                <div className="leftSideContainer">
                   <BtnAddEmployee />
                  <MoreVertIcon />
                </div>
              </div>
            
              {addItem ? (
                <></>
              ) : (
                <table className="table" >
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        />
                      </th>

                      <th>User Name</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      employees?.data && employees.data.length ? (
                        employees.data.map((item) => (            
                           <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={item.selected}
                            onChange={() => handleCheckboxChange(item.id)}
                            onClick={() => {
                              setOpenDetails(true);
                            }}
                          />
                        </td>

                        <td>{item.username}</td>
                        <td>{item.name}</td>
                        <td>
              {employeeRoles?.map((emprole) => (                 
                 (emprole.id== item.role ? emprole.role : "")
              ))}</td>
                        <td>{item.email}</td>
                        <td>
                          <button id={item.status === "Active" ? "projectStateBlueBtn" : "projectStateBtn"}>{item.status}</button>

                        </td>
                        
                        <td id="actions">
                          <CreateIcon style={{ color: "#38B159" }} />{" "}
                          <DeleteForeverIcon
                            style={{ color: "red" }}                            
                            onClick={(e) => deleteProject(item._id)}
                          />
                        </td>
                      </tr>
                          ))) : (
                            <p>{isLoading ? "Loading..." : "data not found"}</p>
                          )
                    }


                   
                  </tbody>
                </table>
              )}
            </div>
              )
          }
        </div>
      </div>
            
      
    </>
  );
}

export default Index;

// <Dropdown className="drop">
// <Dropdown.Toggle variant="success" id="dropdown-basic">
//   Location
// </Dropdown.Toggle>
