import "./Widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

const Widget = ({ type, dashboarData=0 }) => {
  let data;

  //temporary
  const amount = 100;
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "View all orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,
        link: "View net earnings",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See details",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    case "active_project":
      data = {
        title: "Active Projects",
        isMoney: false,
        link: "See all users",
        icon: (
          <AddTaskOutlinedIcon
            className="icon"
            style={{
              color: "blue",
              //backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "complete_project":
      data = {
        title: "Completed Project",
        isMoney: false,
        link: "View all orders",
        icon: (
          <UpdateOutlinedIcon
            className="icon"
            style={{
              //backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "green",
            }}
          />
        ),
      };
      break;
    case "geegr_credit":
      data = {
        title: "Geegr Credits",
        isMoney: true,
        link: "View net earnings",
        icon: (
          <CreditCardOutlinedIcon
            className="icon"
            style={{ 
              //backgroundColor: "rgba(0, 128, 0, 0.2)", 
              color: "red" 
            }}
          />
        ),
      };
      break;
    case "customer_review":
      data = {
        title: "Cutomer Reviews",
        isMoney: false,
        link: "See details",
        icon: (
          <RateReviewOutlinedIcon
            className="icon"
            style={{
              //backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "orange",
            }}
          />
        ),
      };
      break;
    case "total_earning":
        data = {
          title: "Total Earnings",
          isMoney: true,
          link: "See details",
          icon: (
            <CreditCardOutlinedIcon
              className="icon"
              style={{
                //backgroundColor: "rgba(128, 0, 128, 0.2)",
                color: "red",
              }}
            />
          ),
        };
        break;
      case "open_proposal":
            data = {
              title: "Open Proposal",
              isMoney: false,
              link: "See details",
              icon: (
                <LabelOutlinedIcon
                  className="icon"
                  style={{
                    //backgroundColor: "rgba(128, 0, 128, 0.2)",
                    color: "orange",
                  }}
                />
              ),
            };
            break;
    default:
      break; 
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title fw-semibold">{data.title}</span>
        <span className="counter fw-bold">
          {data.isMoney && "$"}{dashboarData}
        </span>
        {/* <span className="link">{data.link}</span> */}
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
