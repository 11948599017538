import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import {
    MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBTabs,
  MDBTabsLink,
  MDBTabsItem,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";

import Description from "./Description";
import Analytics from "./Analytics";
import ServiceOffering from "./ServiceOffering";
import DocumentUpload from "./DocumentUpload";
import Riewe from "./Riewe";

function TabsOptions() {
  const [basicActive, setBasicActive] = useState('tab1');
  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

 
  return (
    <div className="tabsContainer">
 <div className="topTabsMenuContainer">
 <div>
 <MDBTabs className='mb-3'>
 <MDBTabsItem>
   <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
   Description
   </MDBTabsLink>
 </MDBTabsItem>
 <MDBTabsItem>
   <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
   Analytics
   </MDBTabsLink>
 </MDBTabsItem>
 <MDBTabsItem>
   <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
   Service Offerings
   </MDBTabsLink>
 </MDBTabsItem>
 <MDBTabsItem>
   <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
   Documents Uploaded
   </MDBTabsLink>
 </MDBTabsItem>
 <MDBTabsItem>
   <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
   Reviews
   </MDBTabsLink>
 </MDBTabsItem>
</MDBTabs>
</div>
 <div>
 <div className="togleBtnContainer">
<div>
<button>Vender</button>
customer
</div>

</div>
 </div>
 </div>
   
  
    <MDBTabsContent>
    <MDBTabsPane show={basicActive === 'tab1'}><Description/></MDBTabsPane>
    <MDBTabsPane show={basicActive === 'tab2'}><Analytics/></MDBTabsPane>
    <MDBTabsPane show={basicActive === 'tab3'}><ServiceOffering/></MDBTabsPane>
    <MDBTabsPane show={basicActive === 'tab4'}><DocumentUpload/></MDBTabsPane>
    <MDBTabsPane show={basicActive === 'tab5'}><Riewe/></MDBTabsPane>
  </MDBTabsContent>
  </div>
  );
}

export default TabsOptions;
