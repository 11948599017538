import { useSelector } from "react-redux";
import { Rating } from "@mui/material";
import Moment from 'moment';
const AboutVendor = () => {
    const {NewChats:{proposal}} = useSelector((state) => state);

    return (
        <div className="card mb-3 shadow bg-body rounded-3">
        <div className="card-body">
        <div className="project-vendor-details">
            <h6 className="fw-bold">Project Information</h6>
            <p>{proposal?.project?Moment(proposal?.project.created_at, "YYYYMMDD").fromNow():'-'}</p>
            <table>
                <tr>
                    <th>Project Name</th>
                    <th>Location</th>
                </tr>
                <tr>
                    {/* <td><span className="span-h5">Plumbing Job</span></td> */}
                    <td><span className="span-h5">{proposal?.project.title}</span></td>
                    {/* <td>San Diego, CA</td> */}
                    <td>{proposal?.project.location}</td>
                </tr>
            </table>
            <a>View Project Description</a>
        </div>
        </div>
        </div>
        
    )
}
export default AboutVendor;