import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useState, useRef } from "react";
import { useEffect } from 'react';
function CustomFile({message}){

const [FileType,setFileType] = useState("");

useEffect(()=>{
    setFileType(message.data.file[0].file.split('.').pop())
},[message])
return (
    <div>
    <a style={{display: "flex"}} target='_blank' href={message.data.file[0].file}>    
    {(() => {
      switch(FileType) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          return <img width='200' src={message.data.file[0].file} />
        default:
          return <><SaveOutlinedIcon style={{margin: "8px"}} /> <h2>{FileType} File</h2></>
      }
    })()}
    </a>
  </div>
)

}

export default CustomFile;
