import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
//formik import
import { Formik } from "formik";
import * as Yup from "yup";

//imports for login functionalty
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { signin,social_signin } from "../../redux/actions/UserActions";

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import SweetAlert from "react-bootstrap-sweetalert";

// Creating schema
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(3, "Password must be at least 8 characters"),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  //states
  // const [user, setUser] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [token, setToken] = useState("");
  // const [isLoggedin, setIsLoggedIn] = useState(false);

  const [message, setMessage] = useState("server");

  const getState = useSelector((state) => state);
  console.log(getState, "at login (state value)");

  const { userSignin: userInfo } = useSelector((state) => state);

  useEffect(() => {
    if (userInfo.isLoggedIn) {
      console.log(userInfo, "inside if at login");
      navigate("/customer_dashboard");
    }
  });

  return (
      
        <Container fluid className="login-container mt-5">
          <Row>
            <Col md={12} xs={12}>
              {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
              <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={async (values, {setFieldError}) => {
                  dispatch(signin(values, setFieldError));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                      <form noValidate onSubmit={handleSubmit}>
                        <span>Login</span>
                        {/* Server side error */}
                        <p className="error">
                          {errors.server}
                        </p>
                        {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Enter email id / username"
                          className="form-control inp_text"
                          id="email"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {errors.email && touched.email && errors.email}
                        </p>
                        {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter password"
                          className="form-control"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {errors.password && touched.password && errors.password}
                        </p>
                        <p>
                          Doesn't have an account? <br />
                          <Link to="/signup">Register here!</Link>
                        </p>
                        {/* Click on submit button to submit the form */}
                        <button type="submit">Login</button>
                      </form>

                      <GoogleLogin
                        type="icon"
                        shape="square"
                        onSuccess={async (credentialResponse) => {                      

                            // console.log('Google Login Success:', credentialResponse);

                            const profileObj = jwtDecode(credentialResponse.credential);
                            console.log("profileObj decoded: " , profileObj);

                            // Access and log user information
                            const userName = profileObj.name;
                            const userEmail = profileObj.email;                        
                            const sub = profileObj.sub;

                            /* SEND DATA */

                            const data = {                                
                                email: userEmail,
                                sub: sub,
                                social_login_type:"google",
                            };

                            const response = await fetch(process.env.REACT_APP_URL + process.env.REACT_APP_SOCIAL_LOGIN, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(data),
                            });

                            if (response.status == 200) {

                                const responseData = await response.json(); // Parse JSON data
                                console.log("responseData: " + responseData);

                                setAlertMessage('You have successfully registered. Please login.');
                                //setShowSuccessAlert(true);
                                dispatch(social_signin(data, ""));
                              
                            } else {
                              
                                const responseData = await response.json(); // Parse JSON data
                                console.log('Server Response 2:', responseData.error);

                                setAlertMessage(responseData.error);
                                setShowErrorAlert(true);
                            }
                        
                          /* SEND DATA */
                            
                          }}
                          onError={() => {
                                console.log('Login Failed');
                                setAlertMessage('Login Failed. Please try again.');
                                setShowErrorAlert(true);
                          }}
                      />

                    </div>
                  </div>
                )}
              </Formik>         

                          <SweetAlert
                show={showSuccessAlert}
                success
                title="Success!"
                onConfirm={() => {
                  navigate('/login');
                  setShowSuccessAlert(false);
                }}
              >
                {alertMessage}
              </SweetAlert>
              
              <SweetAlert
                show={showErrorAlert}
                warning
                title="Error!"
                onConfirm={() => {
                  navigate('/signup');
                  setShowErrorAlert(false);
                }}
              >
                {alertMessage}
              </SweetAlert>     

            </Col>
          </Row>
        </Container>
    
  );
}

export default Login;
