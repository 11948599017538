import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCollapse,
    MDBTextArea,
  } from "mdb-react-ui-kit";
  import { Rating } from "@mui/material";
  import { useEffect, useState } from "react";
  import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
  import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
  import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
  import useGetPosts from "src/Hooks/useGetPosts";
  import useSavePosts from "src/Hooks/useSavePosts";  
  import { PayPalScriptProvider } from "@paypal/react-paypal-js";
  import Checkout from './Checkout';

  function PaymentModel({payment_show,onOutSideClick, request_data,onPaymentDone,line_items}){
    console.log("request_data:" , request_data);

    localStorage.setItem("request_data_store",JSON.stringify(request_data));

    console.log("Paypal Client ID :" , process.env.REACT_APP_PAYPAL_CLIENT_ID);
    
    const initialOptions = {
      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,                    
      currency: "USD",
      intent: "capture",
    };

    const [modalState,setModalState]=useState(false)
    const [error,setError]=useState('')
    const [paymentMethod,setPaymentMethod]=useState("Paypal") // Default Paypal
    const {data:get_checkout_info,getdata:getCheckoutInfo}=useGetPosts({url: "get_checkout_info",onLoad:"false",data: request_data})
    const {sendData:pay}=useSavePosts('pay')
    const [selectPayment, setSelectPayment] = useState({cc: false, gc: false, pp: false});
    const [request_data_save,setRequestDataSave]=useState(request_data)


    const [loading, setloading] = useState(false);
   
    const modalOnSubmit=async (e)=>{
      // e.preventDefault();

      let request_data_save = JSON.parse(localStorage.getItem("request_data_store"));
      request_data_save.is_pay = true;

      // console.log("modalOnSubmit called" , request_data_save);
      setError('');
      const result=await pay({...request_data_save,payment_method: paymentMethod})
      // console.log(result,'pay')
      if(result.status==true){
        onPaymentDone(result);
        setModalState(false)      
      } else{
        setError(result.error)
      }
    }

    const onPressPaypal = async (data) => {
        // console.log("request_data: " , data);
        setloading(true)
        /*try {
          const token = await paypalApi.generateToken()
          const res = await paypalApi.createOrder(token, props.route.params.paymentRequestData)
          setAccessToken(token)
          setID(res.id)
         
          setloading(false)
          if (!!res?.links) {
            const findUrl = res.links.find(data => data?.rel == "approve")
            setPaypalUrl(findUrl.href)
          }
        } catch (error) {
          console.log("error", error)
          setloading(false)
        }*/
    }

    useEffect(() =>{

      if(modalState===false){
        onOutSideClick()
      } else{
        getCheckoutInfo(request_data)
      }
    },[modalState])

    const CheckPaymentMethod = (e) => {
        // console.log(e.currentTarget.value);
        if(e.currentTarget.value === 'cc') {
            setSelectPayment({cc: true, gc: false, pp: false})
        } else if(e.currentTarget.value === 'gc') {
            setSelectPayment({cc: false, gc: true, pp: false})
        } else if(e.currentTarget.value === 'pp') {
            setSelectPayment({cc: false, gc: false, pp: true})
        } else {
            setSelectPayment({cc: false, gc: false, pp: false})
        }
    }
  
    return(
    <MDBModal show={payment_show} setShow={setModalState} tabIndex="-1">
      <MDBModalDialog className="proposal-payment" size="lg">
            <MDBModalContent>
                <MDBModalHeader>
                    <MDBModalTitle>
                        Choose Payment Method
                    </MDBModalTitle> 
                </MDBModalHeader>  
                <MDBModalBody>
                    {error && error!='' && <div className="alert alert-danger">{error}</div>}
                    <form className="paymentform"
                            onSubmit={(e) => e.preventDefault()} >
                        <div className="category-dropdown mb-1 sharediv" style={{ width:"100%" }}>
                                <h3 className="paymenttitle"><EventNoteOutlinedIcon /> Service List</h3>
                                <table className="table products">
                                    
                                    <tbody>                                        
                                        {get_checkout_info?.line_items?.map((item, index) => {
                                                
                                         return (                                                 
                                                <tr key={index}>
                                                    <th>{item.title}</th>
                                                    <td><span className="float-end">${item.budget}</span></td>
                                                </tr>
                                            )
                                        })}

                                        {/* <tr className="text-muted border-top">
                                            <th>Amount</th>
                                            <td><span className="float-end">${get_checkout_info?.data?.purchase_units[0]?.amount?.value}</span></td>
                                        </tr> */}
                                       
                                        <tr className="text-muted">
                                            <th>Service Charge</th>
                                            <td><span className="float-end">${get_checkout_info?.payment_details?.service_charge}</span></td>
                                        </tr>
                                        <tr className="text-muted">
                                            <th>Platform Fee</th>
                                            <td><span className="float-end">${get_checkout_info?.payment_details?.platform_fee}</span></td>
                                        </tr>
                                    </tbody>
                                    
                                    <tfoot>
                                        <tr>
                                            <th className="fw-bold">Total Billed Amount</th>
                                            <td><span className="float-end">${get_checkout_info?.data?.purchase_units[0]?.amount?.value}</span></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                {/* <h4 className="methodtitle fs-6 mb-3">Choose Payment Method</h4> */ }

                                
                                {/* <table className="table paymentmethods">
                                    
                                        <tr className="paymentmm">
                                                <td><div className="circle checked"></div></td>
                                                <td colspan="2">Debit/Credit/ATM Cards</td>
                                        </tr>
                                        {get_checkout_info?.data?.methods?.map((value,index) =>{
                                            return (
                                                <tr onClick={(e) =>setPaymentMethod(value.payment_id)} 
                                                    
                                                >
                                                    <td><div className={value.payment_id==paymentMethod?"box checked":"box"} ></div></td>
                                                    <td>{value.type}</td>
                                                    <td className="text-right"><>XXXX XXXX XXXX {value.card_last_digits}</></td>
                                                </tr>
                                            )
                                        })}

                                </table> */}
                        </div>
                    </form>                    

                    <PayPalScriptProvider options={initialOptions}>
                        {/*<Checkout payment_props={delete get_checkout_info?.data?.purchase_units[0].items && get_checkout_info?.data?.purchase_units[0]} onApproveOrder={modalOnSubmit} />*/}
                        <Checkout payment_props={get_checkout_info?.data?.purchase_units[0]} onApproveOrder={modalOnSubmit} />
                    </PayPalScriptProvider>


                    {/* <div>
                        <MDBBtn                        
                        onClick={(e) => onPressPaypal(get_checkout_info?.data)}
                        className="btn btn-primary float-end">
                        Select Payment Method
                        </MDBBtn>
                    </div> */ }
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
    )
}
export default PaymentModel;
